import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LOGO from '../../assets/logoText.png'
import { ChartBarIcon, ClipboardIcon, MenuIcon, StoreIcon, UsersGroupIcon, WalletIcon } from '../../assets/icons/svg-icons'

const PAGES = [
  {
    name: 'Dashboard',
    icon: <ChartBarIcon className='h-5 w-auto text-inherit' />,
    url: '/'
  },
  {
    name: 'Pedidos',
    icon: <ClipboardIcon className='h-5 w-auto text-inherit' />,
    url: '/pedidos'
  },
  {
    name: 'Pagos',
    icon: <WalletIcon className='h-5 w-auto text-inherit' />,
    url: 'pagos'
  },
  {
    name: 'Tienda',
    icon: <StoreIcon className='h-5 w-auto text-inherit' />,
    url: '/tienda'
  },
  {
    name: 'Organización',
    icon: <UsersGroupIcon className='h-5 w-auto text-inherit' />,
    url: '/organizacion'
  }
]

const Sidemenu = ({ className, withHeader = false, toggleOpen }) => {
  const [currentPath, setCurrentPath] = useState('')
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setCurrentPath(pathname)
    if (toggleOpen) {
      toggleOpen()
    }
  }, [pathname])

  const getCurrent = (href) => {
    if (href === '/') {
      return currentPath === href ? 'bg-custom_gray_transparent' : ''
    }
    return currentPath.startsWith(href) ? 'bg-custom_gray_transparent' : ''
  }

  return (
    <aside className={`flex-shrink-0 ${withHeader ? 'bg-custom_blue min-w-72' : 'bg-custom_gray_transparent min-w-60'} h-full ${className}`} onClick={(e) => e.stopPropagation()}>
      {
        withHeader && toggleOpen &&
          <div className='flex items-center justify-start gap-4 p-4 bg-white'>
            <button
              className='w-8 h-8 rounded-lg hover:bg-custom_gray_transparent focus:bg-custom_gray_transparent flex items-center justify-center p-1 outline-none'
              onClick={toggleOpen}
            >
              <MenuIcon className='text-black w-5' />
            </button>
            <figure
              className='max-w-40 h-auto overflow-hidden cursor-pointer'
              onClick={() => navigate('/')}
            >
              <img
                src={LOGO}
                alt='Logo tipografico de Logistika Urbana'
                className='filter invert brightness_custom-0'
              />
            </figure>
          </div>
      }
      <nav className='flex flex-col items-stretch justify-start gap-4 py-4 px-4'>
        {
          PAGES.map((page, index) => (
            <Link
              key={index}
              to={page.url}
              className={`flex items-center justify-start gap-4 px-4 py-2 rounded-md hover:bg-custom_gray_transparent ${withHeader ? 'text-white' : 'text-black'} ${getCurrent(page.url)}`}
            >
              {page.icon}
              <span className='capitalize font-semibold text-base text-inherit'>{page.name}</span>
            </Link>
          ))
        }
      </nav>
    </aside>
  )
}

export default Sidemenu
