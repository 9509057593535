import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ordersService from '../../../../services/ordersService'
import Button from '../../../../components/Button'
import ArrowLeft from '../../../../assets/icons/ArrowLeft.svg'
import ShoppingBagIcon from '../../../../assets/icons/BagIcon.svg'
import TrashIcon from '../../../../assets/icons/Trash.svg'
import MinusCircle from '../../../../assets/icons/MinusCircle.svg'
import PlusCircle from '../../../../assets/icons/PlusCircle.svg'
import MagnifyingGlass from '../../../../assets/icons/MagnifyingGlass.svg'
import Funnel from '../../../../assets/icons/Funnel.svg'
import BrandLogo from '../../../../assets/images/BrandLogo.png'
import ImageError from '../../../../assets/images/ImageError.png'
import ProductCard from '../../../../components/ProductCard'
import itemService from '../../../../services/itemsService'
import InputRange from '../../../../components/InputRange'
import RadioButton from '../../../../components/RadioButton'

const AddLineItemsPage = () => {
  const { orderID } = useParams()
  const navigate = useNavigate()

  const [brands, setBrands] = useState()
  const [isBrandSelected, setIsBrandSelected] = useState(false)
  const [brandSelected, setBrandSelected] = useState({})
  const [currentLineItems, setCurrentLineItems] = useState()
  const [newLineItems, setNewLineItems] = useState([])
  const [items, setItems] = useState()
  const [itemsFiltered, setItemsFiltered] = useState()

  const [isBagOpen, setIsBagOpen] = useState(false)

  const [nameFilter, setNameFilter] = useState('')
  const [categoryFilter, setCategoryFilter] = useState({
    list: [],
    selected: {}
  })
  const [priceRange, setPriceRange] = useState({
    lower: Number.MAX_VALUE,
    higher: Number.MIN_VALUE
  })
  const [minPrice, setMinPrice] = useState(priceRange.lower)
  const [maxPrice, setMaxPrice] = useState(priceRange.higher)
  const [priceFilters, setPriceFilters] = useState({
    min_price: priceRange.lower,
    max_price: priceRange.higher
  })

  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false)

  const fetchOrder = async () => {
    const orderResponse = await ordersService.details(orderID)
    if (orderResponse) {
      setCurrentLineItems(orderResponse.order.order_line_items_details)
    }
  }

  const fetchCategories = async () => {
    const categories = await itemService.categoriesList()

    if (categories) {
      const brandCategoryID = categories.find(category => category.name.toLowerCase() === 'marca' || category.name.toLowerCase() === 'brand')?.id || null

      if (brandCategoryID) {
        const categoriesFiltered = categories.filter(category => category.parent === brandCategoryID)
        const categoriesSorted = categoriesFiltered.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        setBrands(categoriesSorted)
      }

      const categoriesFiltered = categories.filter(category => category.parent === 1 && category.slug !== 'marca')
      setCategoryFilter(prev => ({
        ...prev,
        list: categoriesFiltered.filter(category => category.id !== brandSelected.id),
        selected: categoriesFiltered.reduce((acc, category) => {
          acc[category.slug] = true
          return acc
        }, {})
      }))
    }
  }

  const fetchVapes = async (brand) => {
    const vapes = await itemService.listFiltered(`?category_slugs=${brand.slug}`)

    if (vapes) {
      setIsBrandSelected(true)
      setBrandSelected(brand)
      setItems(vapes)
      setPriceRange({
        lower: Number.MAX_VALUE,
        higher: Number.MIN_VALUE
      })
    }
  }

  const addNewItem = (newItem) => {
    setNewLineItems(prevCart => {
      const productExists = prevCart.some(item => item.id === newItem.id)

      if (productExists) {
        const productIndex = prevCart.findIndex(item => item.id === newItem.id)

        const updatedCart = prevCart.map((item, index) => {
          if (productIndex === index) {
            return {
              ...item
            }
          }
          return item
        })
        return updatedCart
      }

      return [...prevCart, newItem]
    })
  }

  const removeItem = (index) => {
    const newBag = [...newLineItems]
    newBag.splice(index, 1)
    setNewLineItems(newBag)
  }

  const increaseQuantity = (index) => {
    setNewLineItems(prev =>
      prev.map((item, i) => {
        if (i === index && item.quantity < 999) {
          return {
            ...item,
            quantity: item.quantity + 1
          }
        }

        return item
      })
    )
  }

  const decreaseQuantity = (index) => {
    setNewLineItems(prev =>
      prev.map((item, i) => {
        if (i === index && item.quantity > 1) {
          return {
            ...item,
            quantity: item.quantity - 1
          }
        }

        return item
      })
    )
  }

  const handleQuantity = (event, index) => {
    const value = parseInt(event.target.value, 10)
    if (value > 999) {
      setNewLineItems(prev => prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            quantity: 999
          }
        }

        return item
      }))
    } else {
      setNewLineItems(prev => prev.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            quantity: (value === '' || !Number(value)) ? 0 : parseInt(value)
          }
        }

        return item
      }))
    }
  }

  const acceptNewItems = () => {
    const newItems = JSON.parse(window.localStorage.getItem('newLineItems'))
    if (newItems.items.length <= 0) {
      window.localStorage.removeItem('newLineItems')
    }
    navigate(`/pedidos/details/${orderID}`)
  }

  const applyFilter = () => {
    let filtered = items

    if (priceFilters.max_price !== priceRange.higher || priceFilters.min_price !== priceRange.lower) {
      filtered = filtered.filter(item => {
        if (item.organization_price) {
          return item.organization_price >= minPrice && item.organization_price <= maxPrice
        } else {
          return item.default_price >= minPrice && item.default_price <= maxPrice
        }
      })
    }
    const allCategoriesSelected = Object.values(categoryFilter.selected).every(category => category === true)
    if (!allCategoriesSelected) {
      filtered = filtered.filter(item => {
        return item.category.slug?.length === 0 || item.category.slug?.some(category => categoryFilter.selected[category])
      })
    }
    if (nameFilter !== '') {
      filtered = filtered.filter(item => item.name.toLowerCase().includes(nameFilter.toLowerCase()))
    }

    setItemsFiltered(filtered)
    setIsFiltersMenuOpen(false)
  }

  const resetFilters = () => {
    setItemsFiltered(items)
    setNameFilter('')
    setPriceFilters({
      min_price: priceRange.lower,
      max_price: priceRange.higher
    })
    setCategoryFilter(prev => ({
      ...prev,
      selected: Object.keys(prev.selected).reduce((acc, category) => {
        acc[category] = true
        return acc
      }, {})
    }))
    setIsFiltersMenuOpen(false)
  }

  const handleSearchChange = (event) => {
    const value = event.target.value
    setNameFilter(value)
  }

  const handleCheckboxesChange = (category) => {
    setCategoryFilter(prev => ({
      ...prev,
      selected: {
        ...prev.selected,
        [category]: !prev.selected[category]
      }
    }))
  }

  useEffect(() => {
    setPriceFilters(prev => ({
      ...prev,
      min_price: minPrice,
      max_price: maxPrice
    }))
  }, [minPrice, maxPrice])

  useEffect(() => {
    fetchCategories()
  }, [brandSelected])

  useEffect(() => {
    if (items) {
      setItemsFiltered(items)

      let lower = priceRange.lower
      let higher = priceRange.higher

      items.forEach(item => {
        const price = item.organization_price !== null ? item.organization_price : item.default_price

        if (price < lower) {
          lower = price
        }

        if (price > higher) {
          higher = price
        }
      })

      setPriceRange({
        lower,
        higher
      })
      setPriceFilters(prev => ({
        ...prev,
        min_price: lower,
        max_price: higher
      }))
      setMinPrice(lower)
      setMaxPrice(higher)
    }
  }, [items])

  useEffect(() => {
    window.localStorage.setItem('newLineItems', JSON.stringify({
      order: orderID,
      items: [...newLineItems]
    }))
  }, [newLineItems])

  useEffect(() => {
    fetchOrder()
  }, [])

  return (
    <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
      <div className='flex items-start justify-between gap-4'>
        <div className='flex items-center justify-start gap-4'>
          <button
            className='flex items-center justify-center w-8 h-8 p-1 rounded-full hover:bg-grey'
            onClick={() => {
              if (isBrandSelected) {
                setIsBrandSelected(false)
                setIsFiltersMenuOpen(false)
              } else {
                window.localStorage.removeItem('newLineItems')
                navigate(`/pedidos/details/${orderID}`)
              }
            }}
          >
            <img src={ArrowLeft} alt='Black arrow pointing to the left indicating that it is a button to return to the previous page.' className='w-full h-full object-cover' />
          </button>
          <h1 className='font-bold text-2xl'>{isBrandSelected ? brandSelected.name.toUpperCase() : 'Vape Brands'}</h1>
        </div>
        {
          isBrandSelected && (
            <div className='flex items-start justify-end gap-4'>
              <form
                className='flex items-stretch justify-center w-96 h-8 rounded-lg overflow-hidden max-md:w-auto'
                onSubmit={(e) => {
                  e.preventDefault()
                  applyFilter()
                }}
              >
                <button type='submit' className='flex-shrink-0 flex items-center justify-center w-12 bg-gray-400 outline-none'>
                  <img src={MagnifyingGlass} alt='Magnifying glass icon' className='w-5 h-5' />
                </button>
                <input
                  type='search'
                  name='search'
                  placeholder='Search by name'
                  className='flex-1 bg-grey outline-none px-4 text-lg'
                  value={nameFilter}
                  onChange={handleSearchChange}
                />
              </form>
              <div className='relative h-8'>
                <button className='w-8 h-8 px-1.5 rounded-lg outline-none border border-black' onClick={() => setIsFiltersMenuOpen(!isFiltersMenuOpen)}>
                  <img src={Funnel} alt='Funnel icon' className='w-full h-auto' />
                </button>
                <div className={`${!isFiltersMenuOpen ? 'hidden' : 'flex'} flex-col gap-4 absolute bg-white w-96 h-auto px-5 py-4 right-0 top-[130%] rounded-lg border-2 border-black z-40`}>
                  <InputRange
                    label='price'
                    minValue={priceFilters.min_price}
                    maxValue={priceFilters.max_price}
                    setMinValue={setMinPrice}
                    setMaxValue={setMaxPrice}
                    minRange={priceRange.lower}
                    maxRange={priceRange.higher}
                    valueGap={100}
                  />
                  <div>
                    <p className='capitalize text-sm font-semibold after:content-[":"]'>Categories</p>
                    <div className='flex flex-col items-start justify-start gap-3'>
                      {
                        categoryFilter.list.map((category) => (
                          <RadioButton
                            key={category.slug}
                            id={category.slug}
                            label={category.name}
                            value={category.slug}
                            isCheckbox
                            checked={categoryFilter.selected[category.slug]}
                            onChange={() => handleCheckboxesChange(category.slug)}
                          />
                        ))
                      }
                    </div>
                  </div>
                  <div className='flex items-center justify-end gap-4'>
                    <Button text='Reset' btnStyle='secondary' onClick={resetFilters} />
                    <Button text='Apply' onClick={applyFilter} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {
        !isBrandSelected
          ? (
            <section className='grid gap-8 py-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(18rem, 1fr))' }}>
              {
                  brands?.map(brand => (
                    <article key={brand.id} className='flex flex-col items-center justify-start gap-4 p-4 rounded-2xl bg-grey cursor-pointer hover:shadow-[0_0px_10px_2px_rgba(0,0,0,0.4)]' onClick={() => fetchVapes(brand)}>
                      <figure className='w-20 h-auto aspect-square overflow-hidden rounded-lg'>
                        <img src={BrandLogo} alt='Brand logo' />
                      </figure>
                      <p className='font-semibold text-lg'>{brand.name.toUpperCase()}</p>
                    </article>
                  ))
                }
            </section>
            )
          : (
            <div className='grid gap-4 content-start overflow-y-scroll pr-1' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))', height: 'calc(100% - 3rem)' }}>
              {
                  itemsFiltered?.map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      addToCart={addNewItem}
                      isInOrder={currentLineItems.find(item => item.item_detail.id === product.id)}
                      fromOrder
                    />
                  ))
                }
            </div>
            )
      }
      {
        isBrandSelected &&
          <div className='flex items-center justify-end gap-4'>
            <div className='relative w-9 flex items-center justify-center'>
              <button className='relative w-9 h-auto' onClick={() => setIsBagOpen(!isBagOpen)}>
                <img src={ShoppingBagIcon} alt='Shopping cart icon' className='w-9' />
                {
                  newLineItems.length > 0 && <div className='absolute -top-2 -right-2 flex items-center justify-center w-6 h-6 rounded-full bg-white text-xs font-semibold shadow-[0_0_6px_0px_rgba(0,0,0,0.4)]'>+{newLineItems.length}</div>
                }
              </button>
              <div
                className={`${!isBagOpen ? 'hidden' : 'flex'} flex-col items-stretch justify-start gap-3 absolute bottom-[110%] right-0 w-80 max-h-[30rem] p-4 rounded-lg bg-white border-2 border-black z-40 max-md:-right-20 overflow-y-scroll`}
              >
                <div className=''>
                  <p className='font-bold'>New Items</p>
                  {
                    newLineItems.length > 0
                      ? newLineItems.map((item, index) => (
                        <article key={index} className='flex items-stretch justify-start gap-1.5 p-2 rounded-2xl bg-white'>
                          <figure className='flex-shrink-0 self-center w-20 border-2 border-black aspect-square rounded-md overflow-hidden'>
                            <img src={item.images[0] || ImageError} alt={item.images[0] ? item.name : 'Error with the image'} className='w-full h-full object-cover' />
                          </figure>
                          <div className='flex flex-col items-start justify-between gap-0.5 w-full'>
                            <div className='flex items-start justify-between gap-2 w-full'>
                              <p className='flex-1 capitalize text-sm font-semibold text-balance'>{item.name}</p>
                              <button className='flex-shrink-0 w-4 h-auto' onClick={() => removeItem(index)}>
                                <img src={TrashIcon} alt='Trash icon' />
                              </button>
                            </div>
                            <div className='flex items-center justify-center gap-2'>
                              <button className='flex-shrink-0' onClick={() => decreaseQuantity(index)}>
                                <img src={MinusCircle} alt='Minus Circle Icon' className='w-6 h-6' />
                              </button>
                              <input
                                className='w-10 bg-transparent outline-none font-semibold text-xl text-center'
                                value={item.quantity}
                                onChange={(e) => handleQuantity(e, index)}
                              />
                              <button className='flex-shrink-0' onClick={() => increaseQuantity(index)}>
                                <img src={PlusCircle} alt='Plus Circle Icon' className='w-6 h-6' />
                              </button>
                            </div>
                          </div>
                        </article>
                      ))
                      : <p>You have not added any new items</p>
                  }
                </div>
                <div>
                  <p className='font-bold'>Current Items</p>
                  {
                    currentLineItems?.length > 0
                      ? currentLineItems?.map((item, index) => (
                        <article key={index} className='flex items-stretch justify-start gap-1.5 p-2 rounded-2xl bg-white'>
                          <figure className='flex-shrink-0 self-center w-20 border-2 border-black aspect-square rounded-md overflow-hidden'>
                            <img src={item.item_detail.item_images[0]?.image || ImageError} alt={item.item_detail.item_images[0]?.image ? item.item_detail.name : 'Error with the image'} className='w-full h-full object-cover' />
                          </figure>
                          <div className='flex flex-col items-start justify-between gap-0.5 w-full'>
                            <div className='flex items-start justify-between gap-2 w-full'>
                              <p className='flex-1 capitalize text-sm font-semibold text-balance'>{item.item_detail.name}</p>
                            </div>
                          </div>
                        </article>
                      ))
                      : <p>You have not added any new items</p>
                  }
                </div>
              </div>
            </div>
            <Button text='Accept' onClick={acceptNewItems} />
          </div>
      }
    </div>
  )
}

export default AddLineItemsPage
