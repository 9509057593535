import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import inventoryService from '../../../services/inventoryServices'
import { toast } from 'react-toastify'
import { URLToTitle } from '../../../utils/stringFormat'
import { FilterIcon, RestartIcon } from '../../../assets/icons/svg-icons'
import CheckboxInput from '../../../components/CheckboxInput'
import Button from '../../../components/Button'
import SelectField from '../../../components/SelectField'
import ProductCard from '../../../components/ProductCard'

const SORT_OPTIONS = [
  {
    id: 'name_a_z',
    name: 'Aa - Zz'
  },
  {
    id: 'name_z_a',
    name: 'Zz - Aa'
  },
  {
    id: 'price_high_low',
    name: 'Precio (mayor a menor)'
  },
  {
    id: 'price_low_high',
    name: 'Precio (menor a mayor)'
  }
]

const SearchProductPage = () => {
  const [isSidemenuOpen, setIsSidemenuOpen] = useState(false)
  const [isSidebarContainerVisible, setIsSidebarContainerVisible] = useState(false)
  const [sortBy, setSortBy] = useState('name_a_z')
  const [isFiltersButtonActive, setIsFiltersButtonActive] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [nextPageParams, setNextPageParams] = useState('')
  const [filters, setFilters] = useState([])
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [loadError, setLoadError] = useState(false)

  const { searchText } = useParams()

  const openSidemenu = () => {
    setIsSidebarContainerVisible(true)
    setTimeout(() => {
      setIsSidemenuOpen(true)
    }, 10)
  }

  const closeSidemenu = () => {
    setIsSidemenuOpen(false)
    setTimeout(() => {
      setIsSidebarContainerVisible(false)
    }, 300)
  }

  const fetchProducts = async (loadMore = false, newFilters = null, resetSort = false) => {
    const params = loadMore
      ? nextPageParams
      : new URLSearchParams({
        search: searchText,
        page_size: 60,
        ordering: resetSort ? 'name_a_z' : sortBy
      })

    if (!loadMore) {
      const activeFilters = newFilters || filters

      // This code extracts all checked filter slugs from the filters object
      // It flattens the nested structure and joins the slugs with commas
      const slugs = activeFilters
        .flatMap(category =>
          category.children
            .filter(child => child.isChecked)
            .map(child => child.slug)
        )
        .join(',')

      if (slugs) params.append('category_name', slugs)
    }

    try {
      loadMore ? setIsLoadingMore(true) : setIsLoading(true)

      const response = await inventoryService.productList(params)
      const nextPage = response.links.next

      setHasNextPage(!!nextPage)
      if (nextPage) {
        setNextPageParams(new URL(nextPage).searchParams)
      }

      setProducts(prev => loadMore ? [...prev, ...response.results] : response.results)
    } catch (error) {
      console.error(`Error al cargar ${loadMore ? 'más ' : ''}productos`, error)
      if (loadMore) {
        toast.error('Hubo un problema al cargar más productos. Intentalo más tarde')
      }
      setLoadError(true)
    } finally {
      loadMore ? setIsLoadingMore(false) : setIsLoading(false)
    }
  }

  const fetchCategories = async () => {
    const getCategories = async (parent = '161') => { // 161 is the ID of the category 'website'
      const response = await inventoryService.categories(`parent=${parent}`)
      if (response?.data) {
        return response.data
      }
    }
    const mainCategories = await getCategories()

    const categoriesWithChildren = await Promise.all(
      mainCategories.map(async (category) => {
        const children = await getCategories(category.id)
        return {
          name: category.name,
          slug: category.slug,
          isAllChecked: false,
          children: children.map(child => ({
            name: child.name,
            slug: child.slug,
            isChecked: false
          }))
        }
      })
    )

    setFilters(categoriesWithChildren)
  }

  useEffect(() => {
    document.title = `${URLToTitle(searchText)} - Logistika Urbana`
    fetchCategories()
  }, [])

  useEffect(() => {
    document.title = `${URLToTitle(searchText)} - Logistika Urbana`
    fetchProducts()
  }, [sortBy, searchText])

  const handleFiltersCheckbox = (category, slug) => {
    setIsFiltersButtonActive(true)
    setFilters(prevFilters =>
      prevFilters.map(cat => {
        if (cat.name === category.name) {
          const updatedChildren = cat.children.map(child => {
            if (child.slug === slug) {
              return {
                ...child,
                isChecked: !child.isChecked
              }
            }
            return child
          })

          const allChildrenChecked = updatedChildren.every(child => child.isChecked)

          return {
            ...cat,
            isAllChecked: allChildrenChecked,
            children: updatedChildren
          }
        }
        return cat
      })
    )
  }

  const handleToggleAllChildren = (categorySlug) => {
    setIsFiltersButtonActive(true)
    setFilters(prevFilters =>
      prevFilters.map(category => {
        if (category.slug === categorySlug) {
          // Check if any child is unchecked
          const hasUncheckedChild = category.children.some(child => !child.isChecked)

          // If any child is unchecked, check all. Otherwise, uncheck all
          return {
            ...category,
            isAllChecked: hasUncheckedChild,
            children: category.children.map(child => ({
              ...child,
              isChecked: hasUncheckedChild
            }))
          }
        }
        return category
      })
    )
  }

  const applyFilters = () => {
    setIsFiltersButtonActive(false)
    fetchProducts()
  }

  const resetFilters = () => {
    const resetObj = filters.map(category => ({
      ...category,
      isAllChecked: false,
      children: category.children.map(child => ({
        ...child,
        isChecked: false
      }))
    }))
    setFilters(resetObj)

    setIsFiltersButtonActive(false)

    setSortBy('name_a_z')

    fetchProducts(false, resetObj, true)
  }

  return (
    <div className='flex items-start justify-start gap-4'>
      <div
        className={`${isSidebarContainerVisible ? 'block' : 'hidden'} fixed flex items-center justify-center top-0 left-0 h-dvh w-dvw bg-[#00000060] z-50`}
        onClick={closeSidemenu}
      >
        <div className={`flex-shrink-0 bg-white w-72 h-fit min-h-40 p-4 rounded-lg ${isSidemenuOpen && 'scale-100'} scale-0 duration-300 ease-in-out`} onClick={(e) => e.stopPropagation()}>
          <div className='flex items-end justify-between gap-4'>
            <h3 className='font-semibold text-xl'>Filtros</h3>
            <button
              className='flex items-center justify-end gap-1 font-normal text-sm text-custom_gray hover:text-black'
              onClick={() => {
                resetFilters()
                closeSidemenu()
              }}
            >
              Resetear
              <RestartIcon className='h-4 text-inherit' />
            </button>
          </div>
          <div>
            {
              filters.map((category) => (
                <div key={category.slug}>
                  <div className='flex items-center justify-between gap-4 pr-2 border-b border-black'>
                    <h4 className='px-2 py-1 font-semibold text-lg capitalize'>{category.name}</h4>
                    <button
                      className='text-xs w-auto px-2 py-1 font-medium hover:bg-custom_gray_transparent rounded'
                      onClick={() => handleToggleAllChildren(category.slug)}
                    >
                      {category.isAllChecked ? 'Desmarcar todo' : 'Marcar todo'}
                    </button>
                  </div>
                  <ul className='flex flex-col items-start justify-start gap-2 p-4'>
                    {category.children.map((child) => (
                      <li key={child.slug}>
                        <CheckboxInput
                          label={child.name}
                          isChecked={child.isChecked}
                          handleCheck={() => handleFiltersCheckbox(category, child.slug)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            }
          </div>
          <Button
            text='Aplicar filtros'
            className='w-full'
            isDisabled={!isFiltersButtonActive}
            onClick={() => {
              applyFilters()
              closeSidemenu()
            }}
          />
        </div>
      </div>
      <aside className='flex-shrink-0 max-w-60 w-full max-md:hidden'>
        <div className='flex items-end justify-between gap-4'>
          <h3 className='font-semibold text-xl'>Filtros</h3>
          <button className='flex items-center justify-end gap-1 font-normal text-sm text-custom_gray hover:text-black' onClick={resetFilters}>
            Resetear
            <RestartIcon className='h-4 text-inherit' />
          </button>
        </div>
        <div>
          {
            isLoading
              ? (
                <div className='flex flex-col items-stretch justify-start gap-4 py-4'>
                  {
                    Array.from({ length: 6 }).map((_, index) => (
                      <div key={index} className='h-6 bg-custom_gray_transparent rounded-lg animate-pulse' />
                    ))
                  }
                </div>
                )
              : (
                  filters.map((category) => (
                    <div key={category.slug}>
                      <div className='flex items-center justify-between gap-4 pr-2 border-b border-black'>
                        <h4 className='px-2 py-1 font-semibold text-lg capitalize'>{category.name}</h4>
                        <button
                          className='text-xs w-auto px-2 py-1 font-medium hover:bg-custom_gray_transparent rounded'
                          onClick={() => handleToggleAllChildren(category.slug)}
                        >
                          {category.isAllChecked ? 'Desmarcar todo' : 'Marcar todo'}
                        </button>
                      </div>
                      <ul className='flex flex-col items-start justify-start gap-2 p-4'>
                        {category.children.map((child) => (
                          <li key={child.slug}>
                            <CheckboxInput
                              label={child.name}
                              isChecked={child.isChecked}
                              handleCheck={() => handleFiltersCheckbox(category, child.slug)}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                )
          }
        </div>
        <Button text='Aplicar filtros' className='w-full' isDisabled={!isFiltersButtonActive} onClick={applyFilters} />
      </aside>
      <section className='flex-grow grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))' }}>
        <div className='flex items-center justify-between gap-4' style={{ gridColumn: '1 / -1' }}>
          <p>{products.length} productos</p>
          <div className='flex items-end justify-end gap-2'>
            <SelectField
              id='sortField'
              label='ordenar por'
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={(e) => { setSortBy(e.target.value) }}
            />
            <button
              className='min-[768px]:hidden flex items-center justify-center rounded-lg py-1.5 w-[2.3rem] border border-custom_gray bg-white'
              onClick={openSidemenu}
            >
              <FilterIcon className='h-[1.36rem]' />
            </button>
          </div>
        </div>
        {
          isLoading
            ? (
                Array.from({ length: 12 }).map((_, index) => (
                  <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                ))
              )
            : products?.length === 0
              ? (
                <div className='h-60 flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
                  {
                    loadError
                      ? (
                        <p className='text-custom_gray font-medium text-sm'>
                          Hubo un problema al cargar los productos. Intenta
                          <span
                            className='text-custom_blue cursor-pointer'
                            onClick={() => window.location.reload()}
                          >
                            {' recargar la pagina '}
                          </span>
                          o intentalo más tarde.
                        </p>
                        )
                      : <p>No se encontraron productos.</p>
                  }
                </div>
                )
              : (
                <>
                  {products.map(product => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                  {isLoadingMore && (
                    Array.from({ length: 12 }).map((_, index) => (
                      <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                    ))
                  )}
                </>
                )
        }
        {hasNextPage && !isLoadingMore && !isLoading && (
          <div className='flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
            <button
              className='self-center font-sm font-semibold text-custom_gray hover:text-black'
              onClick={() => fetchProducts(true)}
            >
              Mostrar más productos
            </button>
          </div>
        )}
      </section>
    </div>
  )
}

export default SearchProductPage
