export const stringToURL = (str) => {
  return str
    // Replace spaces with hyphens
    .replace(/\s+/g, '-')
    // Remove all non-alphanumeric characters except hyphens
    .replace(/[^a-zA-Z0-9-]/g, '')
    // Convert to lowercase (optional)
    .toLowerCase()
    // Remove multiple consecutive hyphens
    .replace(/-+/g, '-')
    // Remove hyphens from start and end (optional)
    .replace(/^-+|-+$/g, '')
}

export const URLToTitle = (str) => {
  return str
    // Replace hyphens with spaces
    .replace(/-/g, ' ')
    // Capitalize the first letter of each word
    .replace(/\b\w/g, (match) => match.toUpperCase())
    // Remove multiple consecutive spaces
    .replace(/\s+/g, ' ')
    // Remove spaces from start and end (optional)
    .trim()
}
