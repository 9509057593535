import { useState, useEffect } from 'react'

const TextArea = ({ id, name, label = 'label', placeholder = '', value = '', onChange, isDisabled = false }) => {
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    if (value !== '') {
      setIsFocus(true)
    }
  }, [])

  return (
    <div
      className='flex flex-col items-stretch justify-start'
    >
      <label
        htmlFor={id}
        className={`${isFocus ? 'text-black' : 'text-custom_gray'} self-start font-semibold text-xs capitalize`}
      >
        {label}:
      </label>
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        className={`${isFocus ? 'border-black' : 'border-custom_gray'} rounded-lg min-h-24 max-h-60 px-3.5 py-1.5 font-normal text-sm outline-none placeholder:text-custom_gray ${isDisabled ? 'resize-none bg-custom_gray_transparent' : 'bg-white border'}`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          if (value === '') {
            setIsFocus(false)
          }
        }}
        value={value}
        onChange={onChange}
        style={{ fieldSizing: 'content' }}
        disabled={isDisabled}
      />
    </div>
  )
}

export default TextArea
