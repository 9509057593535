import { CheckIcon } from '../assets/icons/svg-icons'

const CheckboxInput = ({ label = 'text', isChecked = false, handleCheck }) => {
  return (
    <div className='w-fit flex items-center justify-start gap-2 cursor-pointer' onClick={handleCheck}>
      <div className={`flex-shrink-0 flex items-center justify-center w-4 h-4 rounded border border-black ${isChecked && 'bg-black'}`}>
        {
          isChecked && <CheckIcon className='text-white' />
        }
      </div>
      <p className='font-normal text-base capitalize select-none'>{label}</p>
    </div>
  )
}

export default CheckboxInput
