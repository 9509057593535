import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import inventoryService from '../../../../services/inventoryServices'
import { toast } from 'react-toastify'
import { URLToTitle } from '../../../../utils/stringFormat'
import CheckboxInput from '../../../../components/CheckboxInput'
import Button from '../../../../components/Button'
import SelectField from '../../../../components/SelectField'
import ProductCard from '../../../../components/ProductCard'
import { ArrowLeftIcon, FilterIcon, RestartIcon } from '../../../../assets/icons/svg-icons'

const SORT_OPTIONS = [
  {
    id: 'name_a_z',
    name: 'Aa - Zz'
  },
  {
    id: 'name_z_a',
    name: 'Zz - Aa'
  },
  {
    id: 'price_low_high',
    name: 'Precio: Menor a Mayor'
  },
  {
    id: 'price_high_low',
    name: 'Precio: Mayor a Menor'
  }
]

const DepartmentSubcategoryPage = () => {
  const [products, setProducts] = useState([])
  const [sortBy, setSortBy] = useState('name_a_z')
  const [isSidemenuOpen, setIsSidemenuOpen] = useState(false)
  const [isSidebarContainerVisible, setIsSidebarContainerVisible] = useState(false)
  const [filters, setFilters] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isFiltersButtonActive, setIsFiltersButtonActive] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [nextPageParams, setNextPageParams] = useState('')
  const [loadError, setLoadError] = useState(false)

  const openSidemenu = () => {
    setIsSidebarContainerVisible(true)
    setTimeout(() => {
      setIsSidemenuOpen(true)
    }, 10)
  }

  const closeSidemenu = () => {
    setIsSidemenuOpen(false)
    setTimeout(() => {
      setIsSidebarContainerVisible(false)
    }, 300)
  }

  const { category } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const fetchCategories = async () => {
    const response = await inventoryService.categories(`parent_slug=${category.replaceAll('-', '')}`)
    if (response?.data) {
      const updatedFilters = {
        type: response.data.reduce((acc, { slug, name }) => ({
          ...acc,
          [slug]: {
            name,
            isChecked: filters.type?.[slug]?.isChecked ?? false
          }
        }), {})
      }
      setFilters(updatedFilters)
    }
  }

  const fetchProducts = async (loadMore = false, newFilters = null, resetSort = false) => {
    const params = loadMore
      ? nextPageParams
      : new URLSearchParams({
        category_name: category.replaceAll('-', ''),
        page_size: 36,
        ordering: resetSort ? 'name_a_z' : sortBy
      })

    if (!loadMore) {
      const activeFilters = newFilters || filters

      // This code extracts all checked filter slugs from the filters object
      // It flattens the nested structure and joins the slugs with commas
      const slugs = Object.entries(activeFilters)
        .flatMap(([category, values]) =>
          Object.entries(values)
            .filter(([key, value]) => value.isChecked)
            .map(([key]) => key)
        )
        .join(',')

      if (slugs) params.append('search', slugs)
    }

    try {
      loadMore ? setIsLoadingMore(true) : setIsLoading(true)

      const response = await inventoryService.productList(params)
      const nextPage = response.links.next

      setHasNextPage(!!nextPage)
      if (nextPage) {
        setNextPageParams(new URL(nextPage).searchParams)
      }

      setProducts(prev => loadMore ? [...prev, ...response.results] : response.results)
    } catch (error) {
      console.error(`Error al cargar ${loadMore ? 'más ' : ''}productos`, error)
      if (loadMore) {
        toast.error('Hubo un problema al cargar más productos. Intentalo más tarde')
      }
      setLoadError(true)
    } finally {
      loadMore ? setIsLoadingMore(false) : setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `${URLToTitle(category)} - Logistika Urbana`
    fetchCategories()
  }, [location])

  useEffect(() => {
    fetchProducts()
  }, [sortBy, location])

  const handleFiltersCheckbox = (category, slug) => {
    setIsFiltersButtonActive(true)
    setFilters({
      ...filters,
      [category]: {
        ...filters[category],
        [slug]: {
          ...filters[category][slug],
          isChecked: !filters[category][slug].isChecked
        }
      }
    })
  }

  const applyFilters = () => {
    setIsFiltersButtonActive(false)
    fetchProducts()
  }

  const resetFilters = () => {
    const resetObj = Object.fromEntries(
      Object.entries(filters).map(([key, subObj]) => [
        key,
        Object.fromEntries(
          Object.entries(subObj).map(([subKey, value]) => [
            subKey,
            {
              name: value.name,
              isChecked: false
            }
          ])
        )
      ])
    )
    setFilters(resetObj)

    setIsFiltersButtonActive(false)

    setSortBy('name_a_z')

    fetchProducts(false, resetObj, true)
  }

  return (
    <div className='flex flex-col items-stretch justify-start gap-4'>
      <div className='flex items-center justify-start gap-4'>
        <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='h-full w-full' />
        </button>
        <h1 className='font-bold text-xl capitalize'>{category.replaceAll('-', ' ')}</h1>
        <div className='h-8 border-l border-r border-black' />
      </div>
      <div className='flex items-start justify-start gap-4'>
        <div
          className={`${isSidebarContainerVisible ? 'block' : 'max-md:hidden'} min-[768px]:block flex-shrink-0 max-w-60 max-md:bg-[#00000066] w-full max-md:fixed max-md:top-0 max-md:left-0 max-md:z-10 max-md:flex items-center justify-center max-md:max-w-full max-md:h-full max-md:p-4`}
          onClick={closeSidemenu}
        >
          <aside
            className={`flex-shrink-0 w-full max-w-80 bg-white h-auto max-md:rounded-lg p-4 max-md:scale-0 ${isSidemenuOpen && 'max-md:scale-100'} duration-300 ease-in-out`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className='flex items-end justify-between gap-4'>
              <h3 className='font-semibold text-xl'>Filtros</h3>
              <button
                className='flex items-center justify-end gap-1 font-normal text-sm text-custom_gray hover:text-black'
                onClick={() => {
                  closeSidemenu()
                  resetFilters()
                }}
              >
                Resetear
                <RestartIcon className='h-4 text-inherit' />
              </button>
            </div>
            <div>
              {
                isLoading
                  ? (
                    <div className='flex flex-col items-stretch justify-start gap-4 py-4'>
                      {
                        Array.from({ length: 6 }).map((_, index) => (
                          <div key={index} className='h-6 bg-custom_gray_transparent rounded-lg animate-pulse' />
                        ))
                      }
                    </div>
                    )
                  : (
                    <>
                      <h4 className='px-2 py-1 font-semibold text-lg border-b border-black'>Tipo</h4>
                      <ul className='flex flex-col items-start justify-start gap-2 p-4'>
                        {
                          Object.entries(filters.type ?? {}).map(([slug, value]) => (
                            <li key={slug}>
                              <CheckboxInput label={value.name} isChecked={value.isChecked} handleCheck={() => handleFiltersCheckbox('type', slug)} />
                            </li>
                          ))
                        }
                      </ul>
                    </>
                    )
              }
            </div>
            <Button
              text='Aplicar filtros'
              className='w-full'
              isDisabled={!isFiltersButtonActive}
              onClick={() => {
                closeSidemenu()
                applyFilters()
              }}
            />
          </aside>
        </div>
        <section className='flex-grow grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))' }}>
          <div className='flex items-center justify-between gap-4' style={{ gridColumn: '1 / -1' }}>
            <p>{products.length} productos</p>
            <div className='flex items-end justify-end gap-2'>
              <SelectField
                id='sortField'
                label='ordenar por'
                options={SORT_OPTIONS}
                value={sortBy}
                onChange={(e) => { setSortBy(e.target.value) }}
              />
              <button
                className='min-[768px]:hidden flex items-center justify-center rounded-lg py-1.5 w-[2.3rem] border border-custom_gray bg-white'
                onClick={openSidemenu}
              >
                <FilterIcon className='h-[1.36rem]' />
              </button>
            </div>
          </div>
          {
            isLoading
              ? (
                  Array.from({ length: 12 }).map((_, index) => (
                    <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                  ))
                )
              : products?.length === 0
                ? (
                  <div className='h-60 flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
                    {
                      loadError
                        ? (
                          <p className='text-custom_gray font-medium text-sm'>
                            Hubo un problema al cargar los productos. Intenta
                            <span
                              className='text-custom_blue cursor-pointer'
                              onClick={() => window.location.reload()}
                            >
                              {' recargar la pagina '}
                            </span>
                            o intentalo más tarde.
                          </p>
                          )
                        : <p>No se encontraron productos.</p>
                    }
                  </div>
                  )
                : (
                  <>
                    {products.map(product => (
                      <ProductCard key={product.id} product={product} />
                    ))}
                    {isLoadingMore && (
                      Array.from({ length: 12 }).map((_, index) => (
                        <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                      ))
                    )}
                  </>
                  )
          }
          {hasNextPage && !isLoadingMore && !isLoading && (
            <div className='flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
              <button
                className='self-center font-sm font-semibold text-custom_gray hover:text-black'
                onClick={() => fetchProducts(true)}
              >
                Mostrar más productos
              </button>
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

export default DepartmentSubcategoryPage
