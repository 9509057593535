import { useEffect, useState } from 'react'

const SelectField = ({ id, name, label = 'label', options, value = 0, onChange, isDisabled = false }) => {
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    if (value !== 0) {
      setIsFocus(true)
    }
  }, [])

  return (
    <div
      className='flex flex-col items-stretch justify-start'
    >
      <label
        htmlFor={id}
        className={`${isFocus ? 'text-black' : 'text-custom_gray'} self-start font-semibold text-xs capitalize`}
      >
        {label}:
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={`${isDisabled ? 'px-3.5 py-1.5 bg-custom_gray_transparent opacity-100 appearance-none' : 'border px-3.5 py-1.5 bg-white'} ${isFocus ? 'border-black' : 'border-custom_gray'} rounded-lg font-normal text-base outline-none placeholder:text-custom_gray`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          if (value === 0) {
            setIsFocus(false)
          }
        }}
        disabled={isDisabled}
      >
        <option value={0}>{label}</option>
        {
          options?.map((option) => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))
        }
      </select>
    </div>
  )
}

export default SelectField
