import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login'
import './styles.css'
import PlaceOrderPage from './pages/PlaceOrder'
import DashboardPage from './pages/Dashboard'
import OrganizationPage from './pages/Organization'
import PaymentsPage from './pages/Payments'
import OrdersPage from './pages/Orders'
import Layout from './layouts/Layout'
import CheckoutPage from './pages/PlaceOrder/CheckoutPage'
import PrivateRoute from './routes/PrivateRoute'
import ProfilePage from './pages/Profile'
import ItemDetailsPage from './pages/PlaceOrder/Item'
import AddAddress from './pages/Organization/AddAddress'
import OrderDetailsPage from './pages/Orders/OrderDetails'
import ForgotPasswordPage from './pages/Login/ForgotPassword'
import PaymentDetailsPage from './pages/Payments/PaymentDetails'
import AddLineItemsPage from './pages/Orders/OrderDetails/AddLineItems'
import ResetPasswordPage from './pages/Login/ForgotPassword/NewPassword'
import ChangePasswordPage from './pages/Profile/ChangePassword'
import WinesSpiritsPage from './pages/PlaceOrder/Departments/WinesSpirits'
import FoodPage from './pages/PlaceOrder/Departments/Food'
import NotFoundPage from './pages/NotFound'
import DepartmentSubcategoryPage from './pages/PlaceOrder/Departments/DepartmentSubcategory'
import SearchProductPage from './pages/PlaceOrder/SearchProduct'
import ShoppingCartPage from './pages/ShoppingCart'

function App () {
  return (
    <div>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path='/acceder' element={<Login />} />
          <Route path='/reset-password' element={<ForgotPasswordPage />} />
          <Route path='/reset-password/new-password' element={<ResetPasswordPage />} />

          <Route element={<Layout />}>
            <Route path='/perfil' element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
            <Route path='/perfil/cambiar-contrasena' element={<PrivateRoute><ChangePasswordPage /></PrivateRoute>} />
            <Route path='/' element={<PrivateRoute><DashboardPage /></PrivateRoute>} />

            <Route path='/tienda' element={<PrivateRoute><PlaceOrderPage /></PrivateRoute>} />

            <Route path='/tienda/buscar/:searchText' element={<SearchProductPage />} />
            <Route path='/tienda/buscar/:searchText/:productName/:productID' element={<ItemDetailsPage />} />

            <Route path='/tienda/vinos-y-licores' element={<PrivateRoute><WinesSpiritsPage /></PrivateRoute>} />
            <Route path='/tienda/vinos-y-licores/:category' element={<PrivateRoute><DepartmentSubcategoryPage /></PrivateRoute>} />
            <Route path='/tienda/vinos-y-licores/:productName/:productID' element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>} />
            <Route path='/tienda/vinos-y-licores/:category/:productName/:productID' element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>} />

            <Route path='/tienda/alimentos-y-bebidas' element={<PrivateRoute><FoodPage /></PrivateRoute>} />
            <Route path='/tienda/alimentos-y-bebidas/:category' element={<PrivateRoute><DepartmentSubcategoryPage /></PrivateRoute>} />
            <Route path='/tienda/alimentos-y-bebidas/:productName/:productID' element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>} />
            <Route path='/tienda/alimentos-y-bebidas/:category/:productName/:productID' element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>} />

            <Route path='/tienda/carrito' element={<PrivateRoute><ShoppingCartPage /></PrivateRoute>} />
            <Route path='/tienda/completar-pedido' element={<PrivateRoute><CheckoutPage /></PrivateRoute>} />

            <Route path='/organizacion' element={<PrivateRoute><OrganizationPage /></PrivateRoute>} />
            <Route path='/organizacion/nueva-direccion' element={<PrivateRoute><AddAddress /></PrivateRoute>} />

            <Route path='pagos' element={<PrivateRoute><PaymentsPage /></PrivateRoute>} />
            <Route path='pagos/details' element={<PrivateRoute><PaymentDetailsPage /></PrivateRoute>} />

            <Route path='/pedidos' element={<PrivateRoute><OrdersPage /></PrivateRoute>} />
            <Route path='/pedidos/details/:orderID' element={<PrivateRoute><OrderDetailsPage /></PrivateRoute>} />
            <Route path='/pedidos/details/:orderID/add-line-items' element={<PrivateRoute><AddLineItemsPage /></PrivateRoute>} />
            <Route path='/pedidos/details/:orderID/add-line-items/item' element={<PrivateRoute><ItemDetailsPage /></PrivateRoute>} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
