import { useEffect, useState } from 'react'
import { useShoppingCart } from '../../../context/ShoppingCartContext'
import { useNavigate, useParams } from 'react-router-dom'
import inventoryService from '../../../services/inventoryServices'
import { URLToTitle } from '../../../utils/stringFormat'
import { ArrowLeftIcon, CircleMinusIcon, CirclePlusIcon } from '../../../assets/icons/svg-icons'
import Button from '../../../components/Button'
import ProductImages from '../../../components/ProductImages'

const ItemDetailsPage = () => {
  const [itemData, setItemData] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [images, setImages] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [loadError, setLoadError] = useState({
    status: false,
    message: ''
  })
  const [productInCart, setProductInCart] = useState({
    status: false,
    quantity: 0
  })

  const { addToCart, shoppingCart } = useShoppingCart()
  const { productName, productID } = useParams()
  const navigate = useNavigate()

  const fetchDetails = async () => {
    try {
      setIsLoading(true)
      const response = await inventoryService.itemDetails(productID)
      setItemData(response)
      setImages(response.item_images.map((image) => image.image))
    } catch (error) {
      setLoadError({
        status: true,
        message: 'Error al cargar los datos del producto'
      })
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `${URLToTitle(productName) || 'Detalles del producto'} - Logistika Urbana`
    fetchDetails()
  }, [])

  useEffect(() => {
    const currentProduct = shoppingCart.find((item) => item.id === parseInt(productID))
    if (currentProduct) {
      setProductInCart({
        status: true,
        quantity: currentProduct.quantity
      })
    }
  }, [shoppingCart])

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10)

    if (value > 999) {
      setQuantity(999)
    } else {
      setQuantity((value === '' || !Number(value)) ? 0 : parseInt(value))
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  return (
    <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
      <div className='flex items-center justify-start gap-4'>
        <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='h-full w-full' />
        </button>
        <h1 className='font-bold text-xl capitalize'>
          {isLoading
            ? <p className='bg-custom_gray h-7 w-80 rounded-2xl animate-pulse' />
            : loadError.status ? 'Error de carga' : URLToTitle(productName)}
        </h1>
      </div>
      <section className='flex items-start justify-center gap-8 max-md:flex-col max-md:items-center max-md:justify-start max-md:gap-2'>
        {isLoading ? <div className='flex-shrink-0 bg-custom_gray animate-pulse rounded-lg w-80 h-96' /> : <ProductImages images={images} />}
        <div className='flex flex-col items-start justify-start gap-4 h-full w-full max-w-2xl'>
          {
            isLoading
              ? (
                <>
                  <div className='bg-custom_gray animate-pulse rounded-lg w-full h-9' />
                  <div className='w-full flex flex-col items-stretch justify-start gap-1'>
                    <p className='bg-custom_gray animate-pulse rounded-lg w-4/5 h-7' />
                    <p className='bg-custom_gray animate-pulse rounded-lg w-3/5 h-7' />
                    <p className='bg-custom_gray animate-pulse rounded-lg w-2/5 h-7' />
                  </div>
                  <p className='bg-custom_gray animate-pulse rounded-lg w-24 h-7' />
                </>
                )
              : (
                <>
                  <h2 className='font-medium text-3xl capitalize'>{loadError.status ? 'Producto no encontrado' : itemData.name}</h2>
                  <p className='text-lg'>{loadError.status ? 'Se produjo un error al cargar los datos del producto' : itemData.description}</p>
                  {!loadError.status && (
                    <>
                      <p className='pl-1 font-medium text-2xl'>${itemData.organization_price || itemData.default_price}</p>
                      <div className='flex items-end justify-end gap-8 w-full'>
                        <div className='flex items-center justify-end gap-1'>
                          <button
                            className='bg-none outline-none w-8 h-8 flex items-center justify-center'
                            onClick={decreaseQuantity}
                          >
                            <CircleMinusIcon className='stroke-2' />
                          </button>
                          <input
                            className='font-semibold text-2xl w-12 text-center bg-none bg-transparent outline-none'
                            type='text'
                            value={quantity}
                            onChange={handleQuantityChange}
                          />
                          <button
                            className='bg-none outline-none w-8 h-8 flex items-center justify-center'
                            onClick={increaseQuantity}
                          >
                            <CirclePlusIcon className='stroke-2' />
                          </button>
                        </div>
                        <Button
                          text='Agregar'
                          onClick={() => addToCart({
                            ...itemData,
                            images,
                            default_price: parseFloat(itemData.default_price),
                            quantity
                          })}
                        />
                      </div>
                    </>
                  )}
                  {productInCart.status && <p className='self-end font-semibold text-custom_gray text-sm'>Hay {productInCart.quantity} en tu carrito</p>}
                </>
                )
          }
        </div>
      </section>
    </div>
  )
}

export default ItemDetailsPage
