import { useEffect, useState } from 'react'
import { useShoppingCart } from '../context/ShoppingCartContext'
import { useNavigate } from 'react-router-dom'
import { stringToURL } from '../utils/stringFormat'
import { CircleMinusIcon, CirclePlusIcon } from '../assets/icons/svg-icons'
import ImageNotFound from '../assets/images/ImageNotFound4.png'

const ProductCard = ({ product, className }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [imgLoaded, setImgLoaded] = useState(false)

  const { addToCart } = useShoppingCart()

  useEffect(() => {
    const img = new window.Image()
    img.src = product?.images[0]
    img.onload = () => {
      setImgLoaded(true)
    }
    img.onerror = () => {
      setImgLoaded(false)
    }
  }, [])

  const navigate = useNavigate()

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10)

    if (value > 999) {
      setQuantity(999)
    } else {
      setQuantity((value === '' || !Number(value)) ? 0 : parseInt(value))
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  const truncateText = (text) => {
    if (text?.length > 100) {
      return text.substring(0, 100).trim() + '...'
    } else {
      return text
    }
  }

  return (
    <div
      className={`flex flex-col items-stretch justify-start gap-2 p-2 bg-white border-2 border-custom_gray rounded-2xl hover:border-custom_blue hover:shadow-custom select-none ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <figure className={`relative flex items-center justify-center h-60 rounded-lg overflow-hidden ${imgLoaded ? 'bg-transparent' : 'bg-custom_gray'}`}>
        <img
          src={imgLoaded ? product.images[0] : ImageNotFound}
          alt={imgLoaded ? product.name : 'ImageNotFound'}
          className={`h-full w-full object-${product?.images[0] ? 'contain' : 'cover'}`}
        />
        <div
          className={`absolute left-0 top-0 w-full h-full flex items-center justify-center bg-custom_blue_transparent cursor-pointer ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-100`}
          onClick={() => navigate(`${stringToURL(product.name)}/${product.id}`)}
        >
          <p className='text-white select-none text-center'>Clic para ver más detalles</p>
        </div>
      </figure>
      <h3 className='font-semibold text-base capitalize text-center text-balance'>{product?.name || 'product name'}</h3>
      <p className='font-normal text-base text-pretty'>{truncateText(product?.description)}</p>
      <div className='flex flex-col items-stretch justify-start gap-2 mt-auto'>
        <div className='flex items-end justify-between gap-4'>
          <p className='font-medium text-lg'>${product?.organization_price?.toFixed(2) || product?.default_price?.toFixed(2)}</p>
          <div className='flex items-center justify-end gap-1'>
            <button
              className='bg-none outline-none w-6 h-6 flex items-center justify-center'
              onClick={decreaseQuantity}
            >
              <CircleMinusIcon className='stroke-2' />
            </button>
            <input
              id={`quantity_${product.id}`}
              className='font-semibold text-lg w-10 text-center bg-none bg-transparent outline-none'
              type='text'
              value={quantity}
              onChange={handleQuantityChange}
            />
            <button
              className='bg-none outline-none w-6 h-6 flex items-center justify-center'
              onClick={increaseQuantity}
            >
              <CirclePlusIcon className='stroke-2' />
            </button>
          </div>
        </div>
        <button
          className={`font-semibold text-sm text-white py-1.5 px-6 rounded-lg ${isHovered ? 'bg-custom_blue' : 'bg-custom_gray'}`}
          onClick={() => addToCart({
            ...product,
            quantity
          })}
        >
          Agregar
        </button>
      </div>
    </div>
  )
}

export default ProductCard
