import { useEffect, useState } from 'react'

const Button = ({ text = 'Button', onClick, type = 'button', btnStyle = 'primary', className = '', isDisabled = false }) => {
  const [styles, setStyles] = useState('')

  useEffect(() => {
    switch (btnStyle) {
      case 'primary':
        setStyles('bg-black text-white hover:shadow-customBlack')
        break
      case 'secondary':
        setStyles('bg-white text-black border border-black hover:shadow-customBlack')
        break
      case 'blue':
        setStyles('bg-custom_blue text-white hover:shadow-custom2')
        break
      case 'danger':
        setStyles('bg-custom_red text-white hover:shadow-customRed')
        break
      default:
        setStyles('bg-black text-white hover:shadow-customBlack')
    }
  }, [btnStyle])

  return (
    <button
      type={type}
      onClick={onClick}
      className={`px-6 py-1.5 font-semibold text-sm rounded-lg w-auto capitalize ${styles} ${className} ${isDisabled ? 'opacity-40 cursor-not-allowed' : ''}`}
      disabled={isDisabled}
    >
      {text}
    </button>
  )
}

export default Button
