import { useEffect, useState } from 'react'

const InputGroup = ({ label = 'label', id, name, value = '', onChange, type = 'text', placeholder = '', className = '', isDisabled = false }) => {
  const [isFocus, setIsFocus] = useState(false)
  const [inputType, setInputType] = useState('text')

  useEffect(() => {
    if (value !== '') {
      setIsFocus(true)
    }

    if (type) {
      switch (type) {
        case 'text':
          setInputType('text')
          break
        case 'number':
          setInputType('number')
          break
        case 'password':
          setInputType('password')
          break
        default:
          setInputType('text')
      }
    }
    if (value === '') {
      setIsFocus(true)
    }
  }, [type])

  return (
    <div
      className={`flex flex-col items-stretch justify-start ${className}`}
    >
      <label
        htmlFor={id}
        className={`${isFocus ? 'text-black' : 'text-custom_gray'} self-start font-semibold text-xs capitalize`}
      >
        {label}:
      </label>
      <input
        id={id}
        name={name}
        type={inputType}
        placeholder={placeholder}
        className={`${isDisabled ? 'px-3.5 py-1.5 bg-custom_gray_transparent' : 'border px-3.5 py-1.5 bg-white'} border-${isFocus ? 'black' : 'custom_gray'} rounded-lg font-normal text-base outline-none placeholder:text-custom_gray`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          if (value === '') {
            setIsFocus(false)
          }
        }}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        readOnly={isDisabled}
      />
    </div>
  )
}

export default InputGroup
