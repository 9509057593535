import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MagnifyingGlassIcon } from '../assets/icons/svg-icons'

const SearchInput = ({ id, placeholder = 'Buscar' }) => {
  const [isFocus, setIsFocus] = useState(false)
  const inputRef = useRef()

  const navigate = useNavigate()

  const handleSearch = (e) => {
    e.preventDefault()
    const searchText = inputRef.current.value
    if (searchText) navigate(`/tienda/buscar/${searchText}`)
  }

  return (
    <form
      onSubmit={handleSearch}
      className={`flex-grow border ${isFocus ? 'border-black' : 'border-custom_gray'} transition-all duration-100 rounded-lg flex items-stretch justify-normal w-full min-w-60 h-8 overflow-hidden`}
    >
      <input
        id={id}
        ref={inputRef}
        type='search'
        placeholder={placeholder}
        className='flex-grow placeholder:text-custom_gray bg-none px-3.5 border-none outline-none text-sm'
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      <button
        className={`flex-shrink-0 ${isFocus ? 'bg-white' : 'bg-custom_gray'} transition-all duration-100 flex items-center justify-center w-8 border-none outline-none`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onClick={handleSearch}
      >
        <MagnifyingGlassIcon className={`${isFocus ? 'text-black' : 'text-white'} w-5 h-5`} />
      </button>
    </form>
  )
}

export default SearchInput
