import { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '../assets/icons/svg-icons'
import defaulImage from '../assets/images/ImageNotFound5.png'

const ProductImages = ({ images = [] }) => {
  const [imageSelectedIndex, setImageSelectedIndex] = useState()
  const [noImages, setNoImages] = useState(true)

  useEffect(() => {
    if (images.length > 0) {
      setNoImages(false)
      setImageSelectedIndex(0)
    }
  }, [])

  const nextImage = () => {
    const nextIndex = imageSelectedIndex + 1
    if (nextIndex < images.length) {
      setImageSelectedIndex(nextIndex)
    } else {
      setImageSelectedIndex(0)
    }
  }

  const previousImage = () => {
    const previousIndex = imageSelectedIndex - 1
    if (previousIndex >= 0) {
      setImageSelectedIndex(previousIndex)
    } else {
      setImageSelectedIndex(images.length - 1)
    }
  }

  return (
    <div className='relative w-80'>
      <figure className='w-80 h-96 border-2 border-custom_gray bg-white rounded-lg overflow-hidden'>
        {
          noImages
            ? (
              <img src={defaulImage} alt='no images' />
              )
            : (
              <img
                src={images[imageSelectedIndex]}
                alt={imageSelectedIndex}
                className='w-full h-full object-contain'
              />
              )
        }
      </figure>
      {
        images.length > 1 && (
          <>
            <button
              className='absolute -right-4 top-[45%] flex items-center justify-center w-8 h-8 bg-white border-2 border-custom_gray rounded-full overflow-hidden'
              onClick={nextImage}
            >
              <ChevronRightIcon className='text-custom_gray ml-[1px]' />
            </button>
            <button
              className='absolute -left-4 top-[45%] flex items-center justify-center w-8 h-8 bg-white border-2 border-custom_gray rounded-full overflow-hidden'
              onClick={previousImage}
            >
              <ChevronLeftIcon className='text-custom_gray mr-[1px]' />
            </button>
            <div className='flex items-start justify-start gap-2 py-1'>
              {
                images.map((image, index) => (
                  <figure
                    key={index}
                    className={`flex-1 h-12 border-2 ${imageSelectedIndex === index ? 'border-custom_blue' : 'border-custom_gray'} rounded-lg cursor-pointer overflow-hidden`}
                    onClick={() => setImageSelectedIndex(index)}
                  >
                    <img
                      src={image}
                      alt={index}
                      className='w-full h-full object-contain'
                    />
                  </figure>
                ))
              }
            </div>
          </>
        )
      }
    </div>
  )
}

export default ProductImages
