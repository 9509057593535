import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShoppingCart } from '../context/ShoppingCartContext'
import { stringToURL } from '../utils/stringFormat'
import { CircleMinusIcon, CirclePlusIcon, TrashIcon } from '../assets/icons/svg-icons'
import ImageNotFound from '../assets/images/ImageNotFound4.png'

const ProductShoppingCard = ({ product, removeFromCart, currentIndex }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [quantity, setQuantity] = useState(product.quantity)
  const [imgLoaded, setImgLoaded] = useState(false)

  useEffect(() => {
    const img = new window.Image()
    img.src = product?.images[0]
    img.onload = () => {
      setImgLoaded(true)
    }
    img.onerror = () => {
      setImgLoaded(false)
    }
  }, [])

  const { setShoppingCart } = useShoppingCart()

  const navigate = useNavigate()

  useEffect(() => {
    setShoppingCart(prev => {
      return prev.map((item, index) => index === currentIndex ? { ...item, quantity } : item)
    })
  }, [quantity])

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value, 10)

    if (value > 999) {
      setQuantity(999)
    } else {
      setQuantity((value === '' || !Number(value)) ? 0 : parseInt(value))
    }
  }

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const increaseQuantity = () => {
    if (quantity < 999) {
      setQuantity(quantity + 1)
    }
  }

  return (
    <div
      className='flex items-stretch justify-start gap-2 h-auto p-2 bg-white border-b-2 border-custom_gray hover:border-custom_blue'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <figure className='relative flex-shrink-0 flex items-center justify-center h-32 aspect-square rounded-lg overflow-hidden'>
        <img
          src={imgLoaded ? product?.images[0] : ImageNotFound}
          alt={imgLoaded ? product?.name : 'ImageNotFound'}
          className={`h-full w-full object-${product?.images[0] ? 'contain' : 'cover'}`}
        />
        <div
          className={`absolute left-0 top-0 w-full h-full flex items-center justify-center bg-custom_blue_transparent cursor-pointer ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-100`}
          onClick={() => navigate(`${stringToURL(product.name)}/${product.id}`)}
        >
          <p className='text-white select-none text-center'>Clic para ver más detalles</p>
        </div>
      </figure>
      <div className='flex-1 flex flex-col items-stretch justify-between gap-2'>
        <div className='flex items-start justify-between gap-4'>
          <div>
            <h3 className='font-semibold text-lg capitalize text-left text-balance'>{product?.name || 'Product Name'}</h3>
            <p className='font-normal text-sm'>${parseFloat(product?.organization_price || product?.default_price).toFixed(2)}</p>
          </div>
          <p className='font-semibold text-lg text-nowrap'>${(product.quantity * parseFloat(product.organization_price || product.default_price).toFixed(2))}</p>
        </div>
        <div className='flex items-center justify-between gap-8'>
          <div className='flex items-center justify-end gap-1'>
            <button
              className='bg-none outline-none w-6 h-6 flex items-center justify-center'
              onClick={decreaseQuantity}
            >
              <CircleMinusIcon className='stroke-2' />
            </button>
            <input
              className='font-semibold text-lg w-10 text-center bg-none bg-transparent outline-none'
              type='text'
              value={quantity}
              onChange={handleQuantityChange}
            />
            <button
              className='bg-none outline-none w-6 h-6 flex items-center justify-center'
              onClick={increaseQuantity}
            >
              <CirclePlusIcon className='stroke-2' />
            </button>
          </div>
          <button
            className='flex items-center justify-center gap-2 hover:text-custom_red'
            onClick={removeFromCart}
          >
            <TrashIcon className='h-4' />
            <span className='font-semibold text-sm'>Remover</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductShoppingCard
