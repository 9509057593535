import api from '../utils/api'

const inventoryService = {
  productList: async (params = '') => {
    try {
      const response = await api.get(`/search/item/?${params}`)
      return response.data
    } catch (error) {
      console.error('Failed', error)
    }
  },
  categories: async (params) => {
    try {
      const response = await api.get(`/inventory/categories/?${params}`)
      return response
    } catch (error) {
      console.error('Failed', error)
    }
  },
  itemDetails: async (itemID) => {
    try {
      const response = await api.get(`/inventory/retail-item/${itemID}/`) // TODO: Change to wholesale endpoint
      return response
    } catch (error) {
      console.error('Failed', error)
    }
  },
  warehouseList: async (params) => {
    try {
      const response = await api.get(`/admin/warehouses/?${params}`)
      return response
    } catch (error) {
      console.error('Failed', error)
    }
  }
}

export default inventoryService
