import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import inventoryService from '../../../../services/inventoryServices'
import { stringToURL } from '../../../../utils/stringFormat'
import ProductCard from '../../../../components/ProductCard'
import SelectField from '../../../../components/SelectField'
import { ArrowLeftIcon } from '../../../../assets/icons/svg-icons'

const SORT_OPTIONS = [
  {
    id: 'name_a_z',
    name: 'Aa - Zz'
  },
  {
    id: 'name_z_a',
    name: 'Zz - Aa'
  },
  {
    id: 'price_low_high',
    name: 'Precio: Menor a Mayor'
  },
  {
    id: 'price_high_low',
    name: 'Precio: Mayor a Menor'
  }
]

const FoodPage = () => {
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [sortBy, setSortBy] = useState('name_a_z')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [nextPageParams, setNextPageParams] = useState('')
  const [loadError, setLoadError] = useState(false)

  const navigate = useNavigate()

  const fetchCategories = async () => {
    const response = await inventoryService.categories('parent=153') // 153 is the ID of the category 'comida'
    setCategories(response.data)
  }

  const fetchProducts = async (loadMore = false) => {
    const params = loadMore
      ? nextPageParams
      : new URLSearchParams({
        category_name: 'comida', // to find all products that have any child category of 'comida'
        page_size: 36,
        ordering: sortBy
      })

    try {
      loadMore ? setIsLoadingMore(true) : setIsLoading(true)

      const response = await inventoryService.productList(params)
      const nextPage = response.links.next

      setHasNextPage(!!nextPage)
      if (nextPage) {
        setNextPageParams(new URL(nextPage).searchParams)
      }

      setProducts(prev => loadMore ? [...prev, ...response.results] : response.results)
    } catch (error) {
      console.error(`Error al cargar ${loadMore ? 'más ' : ''}productos`, error)
      if (loadMore) {
        toast.error('Hubo un problema al cargar más productos. Intentalo más tarde')
      }
      setLoadError(true)
    } finally {
      loadMore ? setIsLoadingMore(false) : setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [sortBy])

  return (
    <div className='flex flex-col items-stretch justify-start gap-4'>
      <div className='flex items-center justify-start gap-4'>
        <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate('/tienda')}>
          <ArrowLeftIcon className='h-full w-full' />
        </button>
        <h1 className='font-bold text-xl'>Alimentos y Bebidas</h1>
        <div className='h-8 border-l border-r border-black' />
        <nav className='flex items-center justify-start gap-4'>
          {categories.map(category => (
            <Link
              key={category.id}
              className='bg-custom_gray_transparent rounded-md px-2 py-1 font-medium text-sm outline-none hover:bg-custom_gray hover:text-white capitalize'
              to={`${stringToURL(category.name)}`}
            >
              {category.name}
            </Link>
          ))}
        </nav>
      </div>
      <section className='grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(15rem, 1fr))' }}>
        <div className='flex items-center justify-between gap-4' style={{ gridColumn: '1 / -1' }}>
          <p>{products.length} productos</p>
          <SelectField
            id='sortField'
            label='ordenar por'
            options={SORT_OPTIONS}
            value={sortBy}
            onChange={(e) => { setSortBy(e.target.value) }}
          />
        </div>
        {
          isLoading
            ? (
                Array.from({ length: 12 }).map((_, index) => (
                  <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                ))
              )
            : products?.length === 0
              ? (
                <div className='h-60 flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
                  {
                    loadError
                      ? (
                        <p className='text-custom_gray font-medium text-sm'>
                          Hubo un problema al cargar los productos. Intenta
                          <span
                            className='text-custom_blue cursor-pointer'
                            onClick={() => window.location.reload()}
                          >
                            {' recargar la pagina '}
                          </span>
                          o intentalo más tarde.
                        </p>
                        )
                      : <p>No se encontraron productos.</p>
                  }
                </div>
                )
              : (
                <>
                  {products.map(product => (
                    <ProductCard key={product.id} product={product} />
                  ))}
                  {isLoadingMore && (
                    Array.from({ length: 12 }).map((_, index) => (
                      <div key={index} className='h-96 bg-custom_gray_transparent animate-pulse rounded-lg' />
                    ))
                  )}
                </>
                )
        }
        {hasNextPage && !isLoadingMore && !isLoading && (
          <div className='flex items-center justify-center' style={{ gridColumn: '1 / -1' }}>
            <button
              className='self-center font-sm font-semibold text-custom_gray hover:text-black'
              onClick={() => fetchProducts(true)}
            >
              Mostrar más productos
            </button>
          </div>
        )}
      </section>
    </div>
  )
}

export default FoodPage
