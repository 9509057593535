import { useNavigate } from 'react-router-dom'
import InputGroup from '../../../components/InputGroup'
import Button from '../../../components/Button'
import { useEffect, useState } from 'react'
import RadioButton from '../../../components/RadioButton'
import { useAuth } from '../../../context/AuthContext'
import userService from '../../../services/userService'

const ChangePasswordPage = () => {
  const [isInputChecked, setIsInputChecked] = useState(false)
  const [passwordsData, setPasswordsData] = useState({
    old_password: '',
    password: '',
    password2: ''
  })
  const [arePasswordsFilled, setArePasswordsFilled] = useState(false)
  const [errorsData, setErrorsData] = useState({
    status: false,
    message: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalContainerOpen, setIsModalContainerOpen] = useState(false)

  const openModal = () => {
    setIsModalContainerOpen(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 10)
  }

  useEffect(() => {
    if (
      passwordsData.old_password !== '' &&
      passwordsData.password !== '' &&
      passwordsData.password2 !== ''
    ) {
      setArePasswordsFilled(true)
    } else {
      setArePasswordsFilled(false)
    }
  }, [passwordsData])

  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleChange = (event) => {
    const { name, value } = event.target

    setPasswordsData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const showPassword = (event) => {
    const checkbox = event.target

    if (checkbox.checked) {
      setIsInputChecked(true)
    } else {
      setIsInputChecked(false)
    }
  }

  const forgotPassword = () => {
    logout(() => navigate('/reset-password'))
  }

  const changePassword = async () => {
    if (passwordsData.password === passwordsData.password2) {
      const response = await userService.changePassword(passwordsData)
      if (response) {
        setErrorsData(prev => ({
          ...prev,
          status: false,
          message: ''
        }))
        openModal()
      } else {
        setErrorsData(prev => ({
          ...prev,
          status: true,
          message: 'The current password is incorrect.'
        }))
      }
    } else {
      setErrorsData(prev => ({
        ...prev,
        status: true,
        message: 'The new password fields must match.'
      }))
    }
  }

  return (
    <div className='flex items-center justify-center h-full'>
      {/* // * MODAL */}
      <div className={`bg-transparent_black fixed top-0 left-0 h-dvh w-dvw p-4 ${isModalContainerOpen ? 'flex' : 'hidden'} items-center justify-center`}>
        <div className={`bg-white max-w-96 w-full p-4 rounded-lg flex flex-col items-stretch justify-start gap-4 scale-0 duration-200 ease-linear ${isModalOpen && 'scale-100'}`} onClick={e => e.stopPropagation()}>
          <h4 className='font-bold text-lg text-pretty'>Password Updated</h4>
          <p className='text-base text-pretty'>Your password has been updated. Log in with your new password.</p>
          <div className='flex items-center justify-end gap-4 flex-wrap'>
            <Button text='Continue' onClick={logout} />
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start gap-4 max-w-96 w-full'>
        <h1 className='text-3xl font-bold'>Change Password</h1>
        <div className='self-stretch flex flex-col gap-4'>
          <InputGroup
            label='current password'
            id='old_password'
            name='old_password'
            type={isInputChecked ? 'text' : 'password'}
            value={passwordsData.old_password}
            onChange={handleChange}
          />
          <InputGroup
            label='new password'
            id='password'
            name='password'
            type={isInputChecked ? 'text' : 'password'}
            value={passwordsData.password}
            onChange={handleChange}
          />
          <InputGroup
            label='repeat new password'
            id='password2'
            name='password2'
            type={isInputChecked ? 'text' : 'password'}
            value={passwordsData.password2}
            onChange={handleChange}
          />
          {
            errorsData.status && <p className='text-sm text-custom_red'>{errorsData.message}</p>
          }
          <RadioButton id='show_password' label='Show passwords' checked={isInputChecked} onChange={showPassword} isCheckbox />
          <button className='self-end font-medium text-grey-800' onClick={forgotPassword}>Forgot your password?</button>
          <div className='flex items-end justify-end gap-4'>
            <Button text='Cancel' btnStyle='secondary' onClick={() => navigate(-1)} />
            <div className='flex-1 flex flex-col items-stretch'>
              <Button text='Change password' onClick={changePassword} isDisabled={!arePasswordsFilled} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordPage
