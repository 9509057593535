import axios from 'axios'
import { toast } from 'react-toastify'

const axiosInstance = axios.create({
  baseURL: 'https://api.logistikaurbana.com/api/v1',
  timeout: 5000
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token')
    config.headers.portal = 3
    if (!config.url.includes('login') && token) {
      config.headers.APOLOAPPAUTH = `Authorization ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      window.location.reload()
    }
    toast.error(error.response.data.error || error.message)

    return Promise.reject(error)
  }
)

const api = {
  get: async (url, params) => {
    const response = await axiosInstance.get(url, { params })
    return response.data
  },

  post: async (url, data) => {
    const response = await axiosInstance.post(url, data)
    return response.data
  },

  put: async (url, data) => {
    const response = await axiosInstance.put(url, data)
    return response.data
  },

  patch: async (url, data) => {
    const response = await axiosInstance.patch(url, data)
    return response.data
  },

  delete: async (url) => {
    const response = await axiosInstance.delete(url)
    return response.data
  }
}

export default api
