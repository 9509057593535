import { Link } from 'react-router-dom'
import LOGO from '../assets/logistikaurbana_logo.png'

const NotFoundPage = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-4 h-dvh'>
      <figure className='w-[200px] h-[200px] rounded-lg overflow-hidden bg-custom_gray'>
        <img src={LOGO} alt='Logo de Logistika Urbana' className='w-full h-full object-contain' />
      </figure>
      <p className='font-bold text-6xl'>404</p>
      <p className='text-lg'>Lo sentimos, la página que estas buscando no existe</p>
      <Link to={-1} className='hover:text-custom_blue hover:font-semibold'>Volver</Link>
    </div>
  )
}

export default NotFoundPage
