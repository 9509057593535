import { useEffect, useState } from 'react'
import { useShoppingCart } from '../../context/ShoppingCartContext'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon, TrashIcon } from '../../assets/icons/svg-icons'
import ProductShoppingCard from '../../components/ProductShoppingCard'
import Button from '../../components/Button'

const ShoppingCartPage = () => {
  const [total, setTotal] = useState(0)
  const { deleteFromCart, shoppingCart, emptyCart } = useShoppingCart()

  const navigate = useNavigate()

  useEffect(() => {
    setTotal(shoppingCart.reduce((acc, item) => {
      return acc + (item.default_price * item.quantity)
    }, 0))
  }, [shoppingCart])

  return (
    <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
      <div className='flex items-center justify-start gap-4'>
        <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate(-1)}>
          <ArrowLeftIcon className='h-full w-full' />
        </button>
        <h1 className='font-bold text-xl capitalize'>Carrito</h1>
      </div>
      <section className='flex items-start justify-start gap-8 max-w-4xl w-full mx-auto max-md:flex-col max-md:items-stretch'>
        {
          shoppingCart.length > 0
            ? (
              <>
                <div className='flex-grow flex flex-col items-stretch justify-start'>
                  {
                    shoppingCart.map((item, index) => (
                      <ProductShoppingCard
                        key={index}
                        product={item}
                        currentIndex={index}
                        removeFromCart={() => deleteFromCart(index)}
                      />
                    ))
                  }
                </div>
                <div className='flex-shrink-0 flex flex-col items-stretch justify-start gap-4 min-w-60'>
                  <div className='flex items-start justify-between gap-4'>
                    <p className='font-semibold text-lg'>Total</p>
                    <p className='font-semibold text-lg'>${total.toFixed(2)}</p>
                  </div>
                  <Button onClick={() => navigate('/tienda/completar-pedido')} text='Proceder al pago' className='w-full' />
                  <Button onClick={() => navigate('/tienda')} text='Continuar comprando' btnStyle='secondary' className='w-full' />
                  <button
                    className='self-center flex items-center justify-center gap-2 hover:text-custom_red'
                    onClick={emptyCart}
                  >
                    <TrashIcon className='h-4' />
                    <span className='font-semibold text-sm'>Vaciar Carrito</span>
                  </button>
                </div>
              </>
              )
            : (
              <div className='flex flex-col items-center justify-start gap-2 w-full'>
                <p className='text-lg'>Tu carríto esta vacío.</p>
                <p className='font-medium text-base text-custom_blue hover:text-custom_gray cursor-pointer' onClick={() => navigate('/tienda')}>Ir a comprar</p>
              </div>
              )
        }
      </section>
    </div>
  )
}

export default ShoppingCartPage
