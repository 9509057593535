import { useEffect, useRef, useState } from 'react'
import inventoryService from '../../../services/inventoryServices'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../context/AuthContext'
import { useShoppingCart } from '../../../context/ShoppingCartContext'
import ordersService from '../../../services/ordersService'
import { toast } from 'react-toastify'
import { ArrowLeftIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, RestartIcon } from '../../../assets/icons/svg-icons'
import CheckboxInput from '../../../components/CheckboxInput'
import Button from '../../../components/Button'
import InputGroup from '../../../components/InputGroup'
import addressesService from '../../../services/addressesService'
import TextArea from '../../../components/Textarea'

const STEPS = {
  ADDRESS: 'address',
  PAYMENT: 'payment',
  SUCCESS: 'success'
}

const FULFILLMENT_METHODS = {
  SHIP: 2, // The number 2 corresponds to the ID of the 'ship' method in the database
  PICKUP: 4 // The number 4 corresponds to the ID of the 'pickup' method in the database
}

const defaultPageSize = 10

const CheckoutPage = () => {
  const [step, setStep] = useState(STEPS.ADDRESS)
  const [fulfillment, setFulfillment] = useState(FULFILLMENT_METHODS.SHIP)
  const [addresses, setAddresses] = useState([])
  const [shippingAddressSelected, setShippingAddressSelected] = useState()
  const [sameAddress, setSameAddress] = useState(true)
  const [billingAddressSelected, setBillingAddressSelected] = useState()
  const [paymentType, setPaymentType] = useState('card')
  const [creatingOrder, setCreatingOrder] = useState(false)
  const [orderDraftData, setOrderDraftData] = useState()
  const [notes, setNotes] = useState('')
  const [cardData, setCardData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvv: ''
  })
  const [warehouses, setWarehouses] = useState([])
  const [warehouseSelected, setWarehouseSelected] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalWarehouses, setTotalWarehouses] = useState(0)
  const [loading, setLoading] = useState(false)

  const searchRef = useRef()

  const fetchWarehouses = async (pageNumber = 1, pageSize = defaultPageSize, search = '') => {
    try {
      const params = new URLSearchParams({
        page: pageNumber,
        page_size: pageSize,
        ordering: 'address__city'
      })

      if (search) params.append('address__city', search)

      const response = await inventoryService.warehouseList(params)

      return {
        warehouses: response.results.map(warehouse => ({
          id: warehouse.id,
          name: warehouse.name,
          location: warehouse.address.location
        })),
        currentPage: pageNumber,
        totalPages: Math.ceil(response.total / pageSize),
        totalItems: response.total,
        hasNextPage: pageNumber * pageSize < response.total,
        hasPreviousPage: pageNumber > 1
      }
    } catch (error) {
      console.error('Error fetching warehouses:', error)
      throw error
    }
  }

  const loadWarehouses = async (page, search) => {
    try {
      setLoading(true)
      const result = await fetchWarehouses(page, defaultPageSize, search)
      setWarehouses(result.warehouses)
      setCurrentPage(result.currentPage)
      setTotalPages(result.totalPages)
      setTotalWarehouses(result.totalItems)
    } catch (error) {
      console.error('Error de carga', error)
    } finally {
      setLoading(false)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      loadWarehouses(currentPage + 1)
    }
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      loadWarehouses(currentPage - 1)
    }
  }

  const searchWarehouses = (event) => {
    event.preventDefault()
    const searchText = searchRef.current.value
    if (searchText) loadWarehouses(1, searchText)
  }

  const handleCardData = (event) => {
    const { name, value } = event.target

    let formattedValue = value

    if (name === 'number') {
      // Allows numbers with max length 19
      formattedValue = value.replace(/\D/g, '').slice(0, 19)
    } else if (name === 'cvv') {
      // Allows number with max length 4
      formattedValue = value.replace(/\D/g, '').slice(0, 4)
    } else if (name === 'expiry') {
      // Allows format MM/YY
      formattedValue = value.replace(/\D/g, '').slice(0, 4)
      if (formattedValue.length >= 2) {
        formattedValue = formattedValue.slice(0, 2) + '/' + formattedValue.slice(2)
      }
    }

    setCardData((prevData) => ({
      ...prevData,
      [name]: formattedValue
    }))
  }

  const location = useLocation()
  const navigate = useNavigate()
  const { user, loggedIn } = useAuth()
  const { shoppingCart, emptyCart } = useShoppingCart()

  const fetchAddresses = async () => {
    const response = await addressesService.organizationAddresses(user.organizations[0]?.organization)
    if (response) {
      setAddresses(response)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      fetchAddresses()
      loadWarehouses(1)
    }
  }, [])

  const draftOrder = async () => {
    const newOrderPayload = {
      organization: user.organizations[0]?.organization,
      order: {
        fulfillment_method: fulfillment,
        shipping_address: fulfillment === FULFILLMENT_METHODS.SHIP ? shippingAddressSelected.id : null,
        billing_address: billingAddressSelected.id,
        approval: false,
        notes,
        order_line_items: shoppingCart.map((item) => ({
          item: item.id,
          quantity: item.quantity,
          ...(fulfillment === FULFILLMENT_METHODS.PICKUP && { warehouse: warehouseSelected.id })
        }))
      }
    }

    try {
      const response = await ordersService.create(newOrderPayload)

      if (response.id) {
        setOrderDraftData(response)
        setStep(STEPS.PAYMENT)
      }
    } catch (error) {
      toast.error('Hubo un problema al generar tu orden. Intentalo más tarde.')
      console.error('Error creating order.', error)
    }
  }

  const deleteDraftOrder = async () => {
    const response = await ordersService.delete(orderDraftData.id)
    if (response) {
      setOrderDraftData(null)
    }
  }

  const updateOrderNotes = async (orderID, notes) => {
    try {
      const response = await ordersService.update(orderID, {
        notes
      })
      // Return true if the response has an id, indicating success
      return !!response?.id
    } catch (error) {
      console.error('Error updating order notes:', error)
      return false
    }
  }

  const confirmOrder = async () => {
    try {
      setCreatingOrder(true)
      if (paymentType === 'card') {
        // TODO: implement card verification
        const cardFormCompleted = Object.values(cardData).every(value => value !== '')
        if (cardFormCompleted) {
          if (notes) await updateOrderNotes(orderDraftData.order.id, notes)

          // ? { state: 1 } '1' is the id corresponding to the 'order created' state
          const response = await ordersService.updateFulfillmentHistory({ order: orderDraftData.order.id, state: 1 })
          if (response.id) {
            emptyCart()
            setStep(STEPS.SUCCESS)
          }
        } else {
          toast.error('Completa el formulario')
        }
      } else {
        if (notes) await updateOrderNotes(orderDraftData.order.id, notes)

        // ? { state: 1 } '1' is the id corresponding to the 'order created' state
        const response = await ordersService.updateFulfillmentHistory({ order: orderDraftData.order.id, state: 1 })
        if (response.id) {
          emptyCart()
          setStep(STEPS.SUCCESS)
        }
      }
    } catch (error) {
      console.error('Error confirming order', error)
      toast.error('Hubo un problema al confirmar tu orden. Intentalo más tarde.')
    } finally {
      setCreatingOrder(false)
    }
  }

  const stepPage = () => {
    switch (step) {
      case STEPS.ADDRESS:
        return (
          <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
            <div className='flex items-center justify-start gap-4'>
              <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate(-1)}>
                <ArrowLeftIcon className='h-full w-full' />
              </button>
              <h1 className='font-bold text-xl capitalize'>Datos de envío</h1>
            </div>
            <section className='w-full max-w-lg mx-auto'>
              <div className='flex items-start justify-start gap-8'>
                <CheckboxInput
                  label='envío'
                  handleCheck={() => setFulfillment(FULFILLMENT_METHODS.SHIP)}
                  isChecked={fulfillment === FULFILLMENT_METHODS.SHIP}
                />
                <CheckboxInput
                  label='recolección'
                  handleCheck={() => setFulfillment(FULFILLMENT_METHODS.PICKUP)}
                  isChecked={fulfillment === FULFILLMENT_METHODS.PICKUP}
                />
              </div>
              <div>
                {
                  fulfillment === FULFILLMENT_METHODS.SHIP
                    ? (
                      <div className='flex flex-col items-stretch justify-start gap-4 py-4'>
                        <p className='font-semibold'>Selecciona la dirección de entrega.</p>
                        <div className='grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))' }}>
                          {
                            addresses?.map(address => (
                              <div key={address.id} className='flex items-stretch p-2 rounded-lg border-2 border-custom_gray'>
                                <CheckboxInput
                                  label={address.address_detail.location}
                                  isChecked={shippingAddressSelected?.id === address.address_detail.id}
                                  handleCheck={() => {
                                    setShippingAddressSelected({
                                      id: address.address_detail.id,
                                      location: address.address_detail.location
                                    })
                                    if (!billingAddressSelected || sameAddress) {
                                      setBillingAddressSelected({
                                        id: address.address_detail.id,
                                        location: address.address_detail.location
                                      })
                                    }
                                  }}
                                />
                              </div>
                            ))
                          }
                        </div>
                        <CheckboxInput
                          label='Usar la dirección de entrega para facturación.'
                          isChecked={sameAddress}
                          handleCheck={() => {
                            const newState = !sameAddress
                            if (newState) {
                              setBillingAddressSelected(shippingAddressSelected)
                            }
                            setSameAddress(newState)
                          }}
                        />
                        {!sameAddress && (
                          <>
                            <p className='font-semibold'>Selecciona la dirección de facturación.</p>
                            <div className='grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))' }}>
                              {
                                addresses?.filter(address => address.address_detail.id !== shippingAddressSelected?.id).map(address => (
                                  <div key={address.id} className='flex items-stretch p-2 rounded-lg border-2 border-custom_gray'>
                                    <CheckboxInput
                                      label={address.address_detail.location}
                                      isChecked={billingAddressSelected?.id === address.address_detail.id}
                                      handleCheck={() => setBillingAddressSelected({
                                        id: address.address_detail.id,
                                        location: address.address_detail.location
                                      })}
                                    />
                                  </div>
                                ))
                              }
                            </div>
                          </>
                        )}
                      </div>
                      )
                    : (
                      <div className='flex flex-col items-stretch justify-start gap-4 py-4'>
                        <p className='font-semibold'>Selecciona uno de nuestros puntos de recolección</p>
                        <form
                          className='flex items-center justify-start gap-4'
                          onSubmit={searchWarehouses}
                        >
                          <button
                            type='button'
                            className='w-6 h-6 rounded-full text-custom_gray hover:text-black'
                            onClick={() => {
                              loadWarehouses(1)
                              searchRef.current.value = ''
                            }}
                          >
                            <RestartIcon className='w-full h-auto text-inherit' />
                          </button>
                          <input
                            ref={searchRef}
                            type='search'
                            placeholder='Ingresa tu ciudad'
                            className='px-2 py-1 text-sm bg-custom_gray_transparent rounded-md outline-none flex-grow '
                          />
                          <button
                            className='text-sm w-auto px-2 py-1 font-semibold bg-black text-white rounded'
                            onClick={searchWarehouses}
                          >
                            Buscar
                          </button>
                        </form>
                        <div className='grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))' }}>
                          {
                            loading
                              ? Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className='h-24 p-2 rounded-lg border-2 border-custom_gray' />
                              ))
                              : (
                                  warehouses?.map((warehouse, index) => (
                                    <div key={index} className='flex flex-col items-stretch justify-center p-2 rounded-lg border-2 border-custom_gray'>
                                      <CheckboxInput
                                        label={
                                          <>
                                            <span className='font-semibold'>{warehouse.name}</span> <br /> {warehouse.location}
                                          </>
                                        }
                                        isChecked={warehouseSelected?.id === warehouse.id}
                                        handleCheck={() => setWarehouseSelected({
                                          id: warehouse.id,
                                          location: warehouse.location,
                                          name: warehouse.name
                                        })}
                                      />
                                    </div>
                                  ))
                                )
                          }
                        </div>
                        <div className='flex items-center justify-between gap-4'>
                          <button
                            className={`flex items-center justify-center pr-[2px] w-6 h-6 rounded-full text-black outline-none ${currentPage === 1 ? 'opacity-40' : 'hover:bg-custom_gray_transparent'}`}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                          >
                            <ChevronLeftIcon className='w-auto h-full text-inherit' />
                          </button>
                          <p className='font-semibold text-sm'>{(currentPage - 1) * defaultPageSize + 1} - {currentPage * defaultPageSize > totalWarehouses ? totalWarehouses : currentPage * defaultPageSize} de {totalWarehouses}</p>
                          <button
                            className={`flex items-center justify-center pl-[2px] w-6 h-6 rounded-full text-black outline-none ${currentPage === totalPages ? 'opacity-40' : 'hover:bg-custom_gray_transparent'}`}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          >
                            <ChevronRightIcon className='w-auto h-full text-inherit' />
                          </button>
                        </div>
                        <p className='font-semibold'>Selecciona la dirección de facturación.</p>
                        <div className='grid gap-4' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))' }}>
                          {
                            addresses?.map(address => (
                              <div key={address.id} className='flex items-stretch p-2 rounded-lg border-2 border-custom_gray'>
                                <CheckboxInput
                                  label={address.address_detail.location}
                                  isChecked={billingAddressSelected?.id === address.address_detail.id}
                                  handleCheck={() => setBillingAddressSelected({
                                    id: address.address_detail.id,
                                    location: address.address_detail.location
                                  })}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      )
                }
              </div>
              <Button
                text='Continuar'
                className='w-full'
                onClick={draftOrder}
                isDisabled={
                  fulfillment !== FULFILLMENT_METHODS.SHIP
                    ? (!warehouseSelected || !billingAddressSelected)
                    : sameAddress
                      ? !shippingAddressSelected
                      : (!shippingAddressSelected || !billingAddressSelected)
                }
              />
            </section>
          </div>
        )
      case STEPS.PAYMENT:
        return creatingOrder
          ? (
            <div className='flex flex-col items-center justify-center gap-8 h-full'>
              <div className='w-40 h-40 rounded-full border-4 border-t-custom_blue border-b-custom_blue border-l-custom_gray border-r-custom_gray animate-spin' />
              <p className='font-bold text-2xl'>Tu pedido se esta procesando...</p>
            </div>
            )
          : (
            <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
              <div className='flex items-center justify-start gap-4'>
                <button
                  className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent'
                  onClick={() => {
                    deleteDraftOrder()
                    setStep(STEPS.ADDRESS)
                  }}
                >
                  <ArrowLeftIcon className='h-full w-full' />
                </button>
                <h1 className='font-bold text-xl capitalize'>Pagar</h1>
              </div>
              <section>
                <div className='max-w-lg flex flex-col items-stretch justify-start gap-4 mx-auto'>
                  <div className='flex items-start justify-start gap-8'>
                    {/* // TODO: Make sure what the payment methods are */}
                    {/* <CheckboxInput label='Efectivo' handleCheck={() => setPaymentType('cash')} isChecked={paymentType === 'cash'} /> */}
                    <CheckboxInput label='Tarjeta' handleCheck={() => setPaymentType('card')} isChecked={paymentType === 'card'} />
                  </div>
                  {
                    paymentType === 'cash'
                      ? (
                        <p>Podras pagar al repartidor al recibir tu pedido.</p>
                        )
                      : (
                        <div className='flex flex-col items-stretch justify-start gap-2'>
                          <InputGroup
                            id='cardUserName'
                            name='name'
                            label='Nombre del titular'
                            value={cardData.name}
                            onChange={handleCardData}
                          />
                          <InputGroup
                            id='cardNumber'
                            name='number'
                            label='Número de tarjeta'
                            type='number'
                            value={cardData.number}
                            onChange={handleCardData}
                          />
                          <div className='flex flex-wrap items-start justify-stretch gap-x-4 gap-y-2'>
                            <InputGroup
                              id='cardDate'
                              name='expiry'
                              label='fecha de expiración'
                              className='flex-grow'
                              value={cardData.expiry}
                              onChange={handleCardData}
                            />
                            <InputGroup
                              id='cardCVV'
                              name='cvv'
                              label='CVV'
                              type='text'
                              className='flex-grow'
                              value={cardData.cvv}
                              onChange={handleCardData}
                            />
                          </div>
                        </div>
                        )
                  }
                  <div>
                    <table className='w-full'>
                      <tbody className='border-b border-custom_gray'>
                        {
                          orderDraftData.order.order_line_items_details.map(lineItem => (
                            <tr key={lineItem.id}>
                              <td className='w-full pr-4 py-2'>{lineItem.item_detail.name}</td>
                              <td className='min-w-12 max-w-20 py-2 pr-4 text-end text-sm align-bottom'>${lineItem.price}</td>
                              <td className='min-w-10 py-2 text-sm align-bottom'>x{parseInt(lineItem.quantity)}</td>
                              <td className='min-w-24 py-2 text-end align-bottom'>${(lineItem.price * lineItem.quantity).toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={3} className='w-24 pt-2 text-sm text-end pr-4'>Total de productos</td>
                          <td className='w-24 pt-2 text-end text-sm'>$
                            {
                              shoppingCart.reduce((acc, item) => {
                                return acc + ((item.organization_price || item.default_price) * item.quantity)
                              }, 0).toFixed(2)
                            }
                          </td>
                        </tr>
                        {
                          orderDraftData?.order.adjustments_detail.length > 0 && (
                            orderDraftData?.order.adjustments_detail.map((adjustment, index) => (
                              <tr key={index}>
                                <td colSpan={3} className='w-24 pt-2 text-sm text-end pr-4 capitalize'>{adjustment.adjustment_name || 'ajuste'}</td>
                                <td className='w-24 pt-2 text-end text-sm'>${parseFloat(adjustment.amount).toFixed(2)}</td>
                              </tr>
                            ))
                          )
                        }
                        <tr>
                          <td colSpan={3} className='w-24 pt-2 font-medium text-end pr-4'>Total a pagar</td>
                          <td className='w-24 pt-2 text-end font-semibold text-lg'>${orderDraftData.order.total_amount.toFixed(2)}</td>
                        </tr>
                        {
                          orderDraftData.order.iva > 0 && (
                            <tr>
                              <td colSpan={3} className='w-24 p-0 text-xs text-end pr-4'>IVA</td>
                              <td className='w-24 p-0 text-end text-xs'>${orderDraftData.order.iva}</td>
                            </tr>
                          )
                        }
                        {
                          orderDraftData.order.ieps > 0 && (
                            <tr>
                              <td colSpan={3} className='w-24 p-0 text-xs text-end pr-4'>IEPS</td>
                              <td className='w-24 p-0 text-end text-xs'>${orderDraftData.order.ieps}</td>
                            </tr>
                          )
                        }
                      </tfoot>
                    </table>
                  </div>
                  {
                    fulfillment === FULFILLMENT_METHODS.SHIP
                      ? <InputGroup label='Entregar en' value={shippingAddressSelected?.location} isDisabled />
                      : <InputGroup label={`recolectar en tienda (${warehouseSelected.name})`} value={warehouseSelected?.location} isDisabled />
                  }
                  <InputGroup label='dirección de facturación' value={billingAddressSelected?.location} isDisabled />
                  <TextArea
                    label='Notas'
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                  <Button text={paymentType === 'card' ? 'Pagar y Completar' : 'Completar'} onClick={confirmOrder} className='w-full' />
                </div>
              </section>
            </div>
            )
      case STEPS.SUCCESS:
        return (
          <div className='flex flex-col items-center justify-center gap-8 h-full'>
            <figure className='flex items-center justify-center h-40 w-40 rounded-full overflow-hidden bg-custom_blue'>
              <CheckIcon className='w-[80%] h-auto text-white' />
            </figure>
            <p className='font-bold text-2xl'>Gracias por tu compra!</p>
            <Button
              text='Ver detalles del pedido'
              onClick={() => navigate(`/pedidos/details/${orderDraftData.id}`)}
            />
            <button
              className='outline-none text-custom_gray hover:text-custom_blue'
              onClick={() => navigate('/')}
            >
              Volver a la tienda
            </button>
          </div>
        )
    }
  }

  if (!loggedIn) {
    return (
      <div className='flex flex-col items-stretch justify-start gap-4 h-full'>
        <div className='flex items-center justify-start gap-4'>
          <button className='h-8 w-8 p-1 rounded hover:bg-custom_gray_transparent' onClick={() => navigate(-1)}>
            <ArrowLeftIcon className='h-full w-full' />
          </button>
          <h1 className='font-bold text-xl capitalize'>Completar orden</h1>
        </div>
        <section>
          <p>Para completar la compra debes <Link to='/perfil/acceder' state={{ from: location.pathname }} className='font-medium text-custom_blue hover:text-custom_gray'>iniciar sesión</Link>.</p>
        </section>
      </div>
    )
  }
  return stepPage()
}

export default CheckoutPage
