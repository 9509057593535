import { useNavigate } from 'react-router-dom'
import SearchInput from '../../components/SearchInput'

const DEPARTMENTS = [
  {
    name: 'Vinos y Licores',
    route: 'vinos-y-licores'
  },
  {
    name: 'Alimentos y Bebidas',
    route: 'alimentos-y-bebidas'
  }
]

const PlaceOrderPage = () => {
  const navigate = useNavigate()

  return (
    <div className='flex flex-col items-stretch justify-start gap-8'>
      <div className='flex items-end justify-between gap-8 max-sm:flex-col max-sm:items-stretch max-sm:gap-2 pt-4 px-4'>
        <p className='flex-shrink-0 text-2xl font-semibold'>¡Bienvenido!</p>
        <div className='flex-grow max-w-3xl'><SearchInput id='landingSearchInput' placeholder='¿Qué quieres comprar hoy?' /></div>
      </div>
      <div className='h-[2px] bg-custom_gray' />
      <section className='flex flex-col items-stretch justify-start gap-4 px-4'>
        <h2 className='font-semibold text-xl'>Departamentos</h2>
        <div className='flex items-start justify-center gap-8 gap-y-2 flex-wrap'>
          {
            DEPARTMENTS.map((department, index) => (
              <article
                key={index}
                className='p-2 bg-white border-2 border-black hover:shadow-customBlack cursor-pointer flex items-center justify-center w-48 h-20 rounded-lg'
                onClick={() => navigate(`${department.route}`)}
              >
                <p className='font-semibold text-xl text-center w-full text-balck select-none'>{department.name}</p>
              </article>
            ))
          }
        </div>
      </section>
    </div>
  )
}

export default PlaceOrderPage
