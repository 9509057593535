import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer'
import { getFormattedDate } from '../../../utils/dateFormatter'

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: 16,
    padding: 32
  },
  label: {
    fontSize: 10,
    fontWeight: 600
  },
  textSmall: {
    fontSize: 12
  },
  dateBadge: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 16
  },
  statusBadge1: {
    backgroundColor: '#23A26D',
    color: 'white',
    fontWeight: 600,
    fontSize: 10,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16
  },
  statusBadge2: {
    backgroundColor: '#FF3B2F',
    color: 'white',
    fontWeight: 600,
    fontSize: 10,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  tableCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4
  },
  tableCellHead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderBottom: '2px solid black'
  },
  tableText1: {
    fontSize: 12
  },
  tableText2: {
    fontSize: 14,
    fontWeight: 700
  },
  pageNumber: {
    position: 'absolute',
    bottom: 16,
    right: 32,
    left: 0,
    textAlign: 'right',
    fontSize: 8,
    color: '#B0AFAF'
  },
  printedDate: {
    position: 'absolute',
    bottom: 16,
    right: 0,
    left: 32,
    textAlign: 'left',
    fontSize: 8,
    color: '#B0AFAF'
  },
  adjustmentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    gap: 4
  },
  adjustmentRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4
  },
  adjustmentCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  textField: {
    fontSize: 12,
    fontWeight: 'normal',
    paddingVertical: 2,
    borderBottom: '1px solid black',
    textTransform: 'capitalize'
  },
  totalBadge: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: '#D9D9D9',
    borderRadius: 8
  }
})

const OrderDetailsPDF = ({ order, adjustmentTypes, warehouseToPickup }) => {
  const getStateHour = (date) => {
    const newDate = new Date(date)
    const hours = newDate.getHours()
    const minutes = newDate.getMinutes()
    return `${hours}:${minutes}`
  }

  const lineItemsTotal = order?.order.order_line_items_details.reduce((total, lineItem) => {
    return total + (lineItem.quantity * lineItem.price)
  }, 0)

  const adjustmentsTotal = order?.order.adjustments_detail.reduce((total, adjustment) => {
    return total + parseFloat(adjustment.amount)
  }, 0)

  const finalTotal = lineItemsTotal + adjustmentsTotal

  return (
    <Document>
      <Page style={styles.page} size='LETTER'>
        <View style={styles.header}>
          <View style={styles.flexRowContainer}>
            <View style={styles.dateBadge}>
              <Text style={styles.label}>Created:</Text>
              <Text style={styles.textSmall}>{getFormattedDate(order.order.created)}</Text>
            </View>
            <View style={styles.dateBadge}>
              <Text style={styles.label}>Updated:</Text>
              <Text style={styles.textSmall}>{getFormattedDate(order.order.updated)}</Text>
            </View>
          </View>
          <Text style={styles.label}>Order ID: <Text style={{ fontSize: 12, fontWeight: 700 }}>{order.id}</Text></Text>
        </View>
        <View style={styles.header}>
          <Text style={order.order.approval ? styles.statusBadge1 : styles.statusBadge2}>{order.order.approval ? 'Approved' : 'Not Approved'}</Text>
        </View>
        {/* // * Line Items Table */}
        <View style={styles.table}>
          {/* // * Table Head */}
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCellHead, flexGrow: 1 }}><Text style={styles.tableText2}>Item Name</Text></View>
            <View style={{ ...styles.tableCellHead, width: 80 }}><Text style={styles.tableText2}>Unit Price</Text></View>
            <View style={{ ...styles.tableCellHead, width: 80 }}><Text style={styles.tableText2}>Quantity</Text></View>
            <View style={{ ...styles.tableCellHead, width: 80 }}><Text style={styles.tableText2}>Total</Text></View>
          </View>
          {/* // * Table Body */}
          {
            order.order.order_line_items_details.map((lineItem, index) => (
              <View style={{ ...styles.tableRow, backgroundColor: (index + 1) % 2 !== 0 ? '#D9D9D9' : 'transparent' }} key={lineItem.id}>
                <View style={{ ...styles.tableCell, flexGrow: 1 }}><Text style={styles.tableText1}>{lineItem.item_detail.name}</Text></View>
                <View style={{ ...styles.tableCell, width: 80 }}><Text style={styles.tableText1}>${lineItem.price}</Text></View>
                <View style={{ ...styles.tableCell, width: 80 }}><Text style={styles.tableText1}>{parseInt(lineItem.quantity)}</Text></View>
                <View style={{ ...styles.tableCell, width: 80 }}><Text style={styles.tableText1}>${lineItem.total_price.toFixed(2)}</Text></View>
              </View>
            ))
          }
          {/* // * Line Items Totals */}
          <View style={{ ...styles.tableRow }}>
            <View style={{ ...styles.tableCell, flexGrow: 1 }}><Text style={styles.tableText1} /></View>
            <View style={{ ...styles.tableCell, width: 80, backgroundColor: '#B0AFAF' }}><Text style={styles.tableText2}>Totals</Text></View>
            <View style={{ ...styles.tableCell, width: 80, backgroundColor: '#B0AFAF' }}>
              <Text style={styles.tableText1}>
                {
                    order.order.order_line_items_details.reduce((acc, lineItem) => {
                      return acc + parseInt(lineItem.quantity)
                    }, 0)
                  }
              </Text>
            </View>
            <View style={{ ...styles.tableCell, width: 80, backgroundColor: '#B0AFAF' }}><Text style={styles.tableText1}>${lineItemsTotal.toFixed(2)}</Text></View>
          </View>
        </View>
        {/* // * Order Adjustments List */}
        <View wrap={false} style={styles.adjustmentsContainer}>
          <Text style={styles.tableText2}>Adjustments</Text>
          {
            order.order.adjustments_detail.length > 0
              ? Array.from({ length: order.order.adjustments_detail.length }).map((_, index) => (
                <View style={styles.adjustmentRow} key={index}>
                  <View style={styles.adjustmentCell}>
                    <Text style={{ fontSize: 12, fontWeight: 700, textTransform: 'capitalize' }}>{adjustmentTypes?.find(type => type.id === order.order.adjustments_detail[index].adjustment_type)?.name}:</Text>
                  </View>
                  <View style={{ ...styles.adjustmentCell, width: 60 }}>
                    <Text style={{ fontSize: 14 }}>${order.order.adjustments_detail[index].amount}</Text>
                  </View>
                </View>
              ))
              : <Text style={{ fontSize: 12 }}>This order has no adjustments.</Text>
          }
        </View>
        {/* // * Order Total */}
        <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start' }}>
          <View style={styles.totalBadge}>
            <Text style={{ fontSize: 14, fontWeight: 600 }}>Total: <Text style={{ fontSize: 16, fontWeight: 700 }}>${finalTotal.toFixed(2)}</Text></Text>
          </View>
          <View wrap={false} style={styles.adjustmentsContainer}>
            {
              order.order.ieps !== 0 && (
                <View style={styles.adjustmentRow}>
                  <View style={styles.adjustmentCell}>
                    <Text style={{ fontSize: 10, fontWeight: 600 }}>IEPS:</Text>
                  </View>
                  <View style={{ ...styles.adjustmentCell, width: 60 }}>
                    <Text style={{ fontSize: 10, fontWeight: 700 }}>${order.order.ieps.toFixed(2)}</Text>
                  </View>
                </View>
              )
            }
            {
              order.order.iva !== 0 && (
                <View style={styles.adjustmentRow}>
                  <View style={styles.adjustmentCell}>
                    <Text style={{ fontSize: 10, fontWeight: 600 }}>IVA:</Text>
                  </View>
                  <View style={{ ...styles.adjustmentCell, width: 60 }}>
                    <Text style={{ fontSize: 10, fontWeight: 700 }}>${order.order.iva.toFixed(2)}</Text>
                  </View>
                </View>
              )
            }
          </View>
        </View>
        {/* // * Order Data */}
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-start', gap: 8, width: 300 }}>
            <View wrap={false}>
              <Text style={{ fontSize: 10, fontWeight: 700 }}>Fulfillment Method:</Text>
              <Text style={styles.textField}>{order.order.fulfillment_method_detail.name}</Text>
            </View>
            {
              order.order.fulfillment_method !== 4 &&
                <View wrap={false}>
                  <Text style={{ fontSize: 10, fontWeight: 700 }}>Shipping Address:</Text>
                  <Text style={styles.textField}>{order.order.shipping_address_detail?.location}</Text>
                </View>
            }
            <View wrap={false}>
              <Text style={{ fontSize: 10, fontWeight: 700 }}>Billing Address:</Text>
              <Text style={styles.textField}>{order.order.billing_address_detail?.location}</Text>
            </View>
            <View wrap={false}>
              <Text style={{ fontSize: 10, fontWeight: 700 }}>Notes:</Text>
              <View style={{ ...styles.flexRowContainer, marginBottom: 8 }}>
                <Text style={{ ...styles.textField, width: '100%' }}>{order.order.notes}</Text>
              </View>
            </View>
          </View>
          {
            warehouseToPickup &&
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: 8 }}>
                <Text style={{ fontSize: 12, fontWeight: 700 }}>The order pickup will be at:</Text>
                <View style={{ maxWidth: 200 }}>
                  <Text style={{ fontSize: 12, fontWeight: 700 }}>{warehouseToPickup.name}</Text>
                  <Text style={{ fontSize: 10, fontWeight: 400 }}>{warehouseToPickup.address.location}</Text>
                </View>
              </View>
          }
        </View>
        {/* // * Fulfillment State History */}
        <View wrap={false}>
          <Text style={{ ...styles.tableText2, marginBottom: 8 }}>Fulfillment State History</Text>
          <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              order.order.fulfillment_state_history.map(state => (
                <View key={state.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'flex-start', gap: 4 }}>
                  <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flexGrow: 1, borderBottom: '1px solid black', borderBottomStyle: 'dashed' }} />
                    <View style={{ width: 10, height: 10, borderRadius: 15, backgroundColor: 'black' }} />
                  </View>
                  <View style={{ paddingRight: 8 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 8 }}>
                      <Text style={{ fontSize: 10 }}>{getFormattedDate(state.updated)}</Text>
                      <Text style={{ fontSize: 10 }}>{getStateHour(state.updated)}</Text>
                    </View>
                    <Text style={{ fontSize: 12, fontWeight: 700, textTransform: 'capitalize' }}>{state.state}</Text>
                    <Text style={{ fontSize: 10 }}>{state.username}</Text>
                  </View>
                </View>
              ))
            }
          </View>
        </View>
        {/* //* Footer */}
        <Text
          style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
          )} fixed
        />
        <Text
          style={styles.printedDate} render={() => (
        `Printed on ${getFormattedDate(new Date())}, at ${getStateHour(new Date())}`
          )} fixed
        />
      </Page>
    </Document>
  )
}

export default OrderDetailsPDF
