import { Link, useNavigate } from 'react-router-dom'
import { useShoppingCart } from '../../context/ShoppingCartContext'
import SearchInput from '../../components/SearchInput'
import LOGO from '../../assets/logoText.png'
import { MenuIcon, ShoppingCartIcon, UserIcon } from '../../assets/icons/svg-icons'

const MainHeader = ({ openSidemenu }) => {
  const { shoppingCart } = useShoppingCart()

  const navigate = useNavigate()
  return (
    <header className='flex flex-col items-center justify-center gap-2 py-4 px-4 bg-custom_blue'>
      <div className='flex items-center justify-between gap-8 w-full max-sm:gap-2 flex-wrap'>
        <div className='flex items-center justify-start gap-4'>
          <button
            className='hidden w-8 h-8 rounded-lg hover:bg-custom_gray_transparent focus:bg-custom_gray_transparent max-lg:flex items-center justify-center p-1 outline-none'
            onClick={openSidemenu}
          >
            <MenuIcon className='text-white w-5' />
          </button>
          <figure
            className='flex-shrink-0 max-w-40 h-auto overflow-hidden cursor-pointer'
            onClick={() => navigate('/')}
          >
            <img
              src={LOGO}
              alt='Logo tipografico de Logistika Urbana'
            />
          </figure>
        </div>
        <div className='flex-grow flex items-center justify-end gap-8 max-w-3xl'>
          <div className='w-full max-sm:hidden'><SearchInput id='searchInput1' placeholder='Buscar productos' /></div>
          <nav className='flex items-start justify-end gap-4'>
            <Link
              className='relative w-8 h-8 rounded-lg hover:bg-custom_gray_transparent focus:bg-custom_gray_transparent flex items-center justify-center p-1 outline-none'
              to='/tienda/carrito'
            >
              <ShoppingCartIcon className='text-white w-5' />
              {
                shoppingCart.length > 0 && (
                  <span className='absolute flex items-center justify-center text-[0.625rem] leading-none bg-custom_blue border border-white text-white font-medium w-4 h-4 rounded-full right-0 top-0'>
                    {shoppingCart.length}
                  </span>
                )
              }
            </Link>
            <Link
              className='relative w-8 h-8 rounded-lg hover:bg-custom_gray_transparent focus:bg-custom_gray_transparent flex items-center justify-center p-1 outline-none'
              to='/perfil'
            >
              <UserIcon className='text-white w-5' />
            </Link>
          </nav>
        </div>
        <div className='w-full hidden max-sm:block'><SearchInput id='searchInput2' placeholder='Buscar productos' /></div>
      </div>
    </header>
  )
}

export default MainHeader
