import { useEffect, useState } from 'react'
import Button from '../../../../components/Button'
import InputGroup from '../../../../components/InputGroup'
import RadioButton from '../../../../components/RadioButton'
import { useNavigate } from 'react-router-dom'
import userService from '../../../../services/userService'

const ResetPasswordPage = () => {
  const [isInputChecked, setIsInputChecked] = useState(false)
  const [arePasswordsFilled, setArePasswordsFilled] = useState(false)
  const [isCodeCorrect, setIsCodeCorrect] = useState(true)
  const [newPasswordData, setNewPasswordData] = useState({
    password: '',
    token: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalContainerOpen, setIsModalContainerOpen] = useState(false)

  const openModal = () => {
    setIsModalContainerOpen(true)
    setTimeout(() => {
      setIsModalOpen(true)
    }, 10)
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (newPasswordData.password !== '' && newPasswordData.token !== '') {
      setArePasswordsFilled(true)
    } else {
      setArePasswordsFilled(false)
    }
  }, [newPasswordData])

  const handlePasswordChange = (event) => {
    const { name, value } = event.target

    setNewPasswordData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const showPassword = (event) => {
    const checkbox = event.target

    if (checkbox.checked) {
      setIsInputChecked(true)
    } else {
      setIsInputChecked(false)
    }
  }

  const changePassword = async () => {
    const response = await userService.resetPasswordDone(newPasswordData)

    if (response) {
      setIsCodeCorrect(true)
      openModal()
    } else {
      setIsCodeCorrect(false)
    }
  }

  return (
    <div className='flex items-center justify-center h-screen'>
      {/* // * MODAL */}
      <div className={`bg-transparent_black fixed top-0 left-0 h-dvh w-dvw p-4 ${isModalContainerOpen ? 'flex' : 'hidden'} items-center justify-center`}>
        <div className={`bg-white max-w-96 w-full p-4 rounded-lg flex flex-col items-stretch justify-start gap-4 scale-0 duration-200 ease-linear ${isModalOpen && 'scale-100'}`} onClick={e => e.stopPropagation()}>
          <h4 className='font-bold text-lg text-pretty'>Password Updated</h4>
          <p className='text-base text-pretty'>Your new password is ready to be used.</p>
          <div className='flex items-center justify-end gap-4 flex-wrap'>
            <Button text='Login' onClick={() => navigate('/acceder')} />
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center justify-start gap-4 max-w-96 w-full'>
        <h1 className='text-3xl font-bold'>Reset Password</h1>
        <p className='font-normal text-base'>Enter the code you received and set a new password.</p>
        <form className='self-stretch flex flex-col gap-4'>
          <InputGroup
            label='code'
            id='code'
            name='token'
            type='number'
            value={newPasswordData.code}
            onChange={handlePasswordChange}
          />
          <InputGroup
            label='new password'
            id='password'
            name='password'
            type={isInputChecked ? 'text' : 'password'}
            value={newPasswordData.password}
            onChange={handlePasswordChange}
          />
          {
            !isCodeCorrect && <p className='text-sm text-custom_red'>Invalid code. Please check that you have entered the recovery code correctly.</p>
          }
          <RadioButton id='show_password' label='Show password' checked={isInputChecked} onChange={showPassword} isCheckbox />
          <div className='flex items-end justify-end gap-4'>
            <Button text='Cancel' btnStyle='secondary' onClick={() => navigate('/acceder')} />
            <Button text='Accept' onClick={changePassword} isDisabled={!arePasswordsFilled} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPasswordPage
